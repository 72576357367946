import {useRef, useLayoutEffect, useCallback} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import useResizeObserver from "../../../../../hooks/useResizeObserver";
import {useSelectorEx} from "../../../../../hooks/redux";

gsap.registerPlugin(ScrollTrigger);

const useContactAnimation = () => {

    const refTarget = useRef(null);
    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const animationLarge = (timeline:any) => {
        gsap.timeline({
            scrollTrigger:{
                id: "useContactAnimation1",
                trigger: refTarget.current,
                scroller: ".scroll-container",
            }
        }).fromTo('.contact-section__form',{
                opacity:0,
                duration: 1,
                delay: 0.5,
            },
            {
                opacity:1,
                duration: 1,
                delay: 0.5,
            },
        )
        gsap.timeline({
            scrollTrigger:{
                id: "useContactAnimation2",
                trigger: refTarget.current,
                scroller: ".scroll-container",
            }
        }).to('.contact-section__input .input',{
                    className: 'input withLine'
                },
        )
    };

    const animationMob = (timeline:any) => {
        gsap.timeline({
            scrollTrigger:{
                id: "useContactAnimation1",
                trigger: refTarget.current,
                scroller: ".scroll-container",
            }
        }).fromTo('.contact-section__form',{
                opacity:0,
                duration: 1,
                delay: 0.1,
            },
            {
                opacity:1,
                duration: 1,
                delay: 0.1,
            },
        )
        gsap.timeline({
            scrollTrigger:{
                id: "useContactAnimation2",
                trigger: refTarget.current,
                scroller: ".scroll-container",
            }
        }).to('.contact-section__input .input',{
                    className: 'input withLine'
         },
       )
    };

    const {preloader,preloaderStatus} = useSelectorEx(state => state.app)

    useLayoutEffect(() => {
        if(!preloader && preloaderStatus >= 100) {
            const timeline = gsap.timeline({
                scrollTrigger:{
                    id: "useContactAnimation3",
                    trigger: refTarget.current,
                    scroller: ".scroll-container",
                }
            });


            const animate = () => {
                let screenWidth:any = window.innerWidth;
                if (screenWidth >= 768) {
                    animationLarge(timeline);
                } else {
                    animationMob(timeline);
                }
            };

            animate();

            const handleResize = () => {
                if(ScrollTrigger.getAll().length >= 1) {
                    ScrollTrigger.getById("useContactAnimation1")?.kill(true);
                    ScrollTrigger.getById("useContactAnimation2")?.kill(true);
                    ScrollTrigger.getById("useContactAnimation3")?.kill(true);
                }
                timeline.kill();
                timeline.clear();
                animate();
            };

            window.addEventListener('resize', handleResize);

            return () => {
                timeline.kill();
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [preloader,preloaderStatus]);

    return {
        refTarget,
        ref1,
        ref2,
    };
};

export default useContactAnimation;


