import gsap from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import { wrapLettersInElements } from '../helpers/scripts';
gsap.registerPlugin(ScrollTrigger);

const FunctionStripEffect = (selector: string, time = 0.1): (() => void) => {

    const formattedStripEffect = () => {
        const stripElement = document.querySelector(selector) as HTMLDivElement;
        if (!stripElement) return;



        const initialText = stripElement.innerHTML;

        stripElement.innerHTML = wrapLettersInElements(stripElement.innerHTML);

        const timeline = gsap.timeline();
        setTimeout(() => {
            const stripSpans = stripElement.querySelectorAll('.line-row div');
            gsap.fromTo(
                stripSpans,
                {
                    opacity: 0,
                    x: 0,
                    y: '100%',
                },
                {
                    opacity: 1,
                    y: '0%',
                    x: 0,
                    duration: 1,
                    delay: 0.1,
                    stagger: time,
                    className: '-=hidden',
                    scrollTrigger: {
                        trigger: stripElement,
                        scroller: '.scroll-container',
                        start: 'top 80%',
                        end: 'bottom 20%',
                    },
                }
            );
        }, 1000);

        return () => {
            timeline.kill();
            stripElement.innerHTML = initialText;
        };
    };

    return formattedStripEffect;
};

export default FunctionStripEffect;