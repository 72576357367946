import React from 'react';

const TelegramIcon = () => {
    return (
        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_0_976)">
                <path d="M21.7539 29.3983C20.2164 30.869 18.7039 32.3275 17.1914 33.786C16.6664 34.2927 16.0789 34.577 15.3164 34.5398C14.804 34.5152 14.5164 34.2927 14.3539 33.8107C13.204 30.2758 12.0289 26.741 10.8789 23.1938C10.7664 22.8478 10.604 22.6871 10.2539 22.5758C7.54144 21.7601 4.81645 20.9196 2.11647 20.0668C1.70397 19.9308 1.27897 19.7702 0.916472 19.523C0.353974 19.1522 0.278974 18.5466 0.778972 18.1016C1.24147 17.6938 1.79147 17.3477 2.36646 17.1252C5.69145 15.8275 9.02897 14.5668 12.3664 13.2938C20.8289 10.0679 29.2914 6.82974 37.7538 3.60389C39.3663 2.98591 40.6038 3.85108 40.4288 5.58142C40.3163 6.68142 40.0288 7.76906 39.8038 8.8567C37.9663 17.4342 36.1288 26.0241 34.2788 34.6016C33.8413 36.641 32.3914 37.1724 30.7039 35.9365C27.8663 33.8724 25.0289 31.796 22.1914 29.7197C22.0539 29.6084 21.9164 29.5096 21.7539 29.3983ZM15.3789 32.0061C15.4164 31.9938 15.4414 31.9938 15.4789 31.9815C15.4914 31.8949 15.5164 31.8208 15.5289 31.7466C15.7164 29.8061 15.9039 27.8534 16.0664 25.9129C16.1039 25.4803 16.2539 25.1713 16.5789 24.8747C19.1914 22.5634 21.8039 20.2275 24.4039 17.9039C27.2914 15.3331 30.1788 12.7623 33.0538 10.1792C33.2288 10.0185 33.3039 9.74657 33.4288 9.5241C33.1538 9.49942 32.8663 9.38819 32.6163 9.44998C32.2788 9.53644 31.9663 9.73423 31.6663 9.91959C25.3039 13.8871 18.9289 17.8545 12.5664 21.8219C12.204 22.0444 12.1414 22.2297 12.2789 22.6252C12.7539 23.9601 13.1789 25.3073 13.6164 26.6545C14.204 28.4342 14.7914 30.2264 15.3789 32.0061Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_0_976">
                    <rect width="40" height="40" fill="white" transform="translate(0.445312)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default TelegramIcon;