import React from 'react';

const BrilliantIcon = () => {
    return (
        <svg width="76" height="80" viewBox="0 0 76 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_155_95)">
                <path d="M15.0472 7.5L2.78442 30.0176M15.0472 7.5H37.5289M15.0472 7.5L23.0124 30.0176M2.78442 30.0176L38.0399 72.5M2.78442 30.0176H23.0124M37.5289 7.5H60.0107M37.5289 7.5L52.3994 30.0176M37.5289 7.5L23.0124 30.0176M23.0124 30.0176L38.0399 72.5M23.0124 30.0176H52.3994M60.0107 7.5L72.7844 30.0176M60.0107 7.5L52.3994 30.0176M72.7844 30.0176L38.0399 72.5M72.7844 30.0176H52.3994M52.3994 30.0176L38.0399 72.5" stroke="white" strokeWidth="3.75" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_155_95">
                    <rect width="75" height="70" fill="white" transform="translate(0.283691 5)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default BrilliantIcon;