import React, {FC} from 'react';

interface IMultiLine{
    className?: string;
}

const MultiLine:FC<IMultiLine> = ({className=''}) => {
    return (
        <div data-scroll={true} data-scroll-speed={2} className={`multi-line ${className}`}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

export default MultiLine;