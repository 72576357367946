import React from 'react';

const AnyIcon = () => {
    return (
        <svg width="676" height="380" viewBox="0 0 676 380" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_79_62" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="9" width="676" height="371">
                <path d="M180.487 9.49551L183.786 13.1241L187.084 13.454L179.662 84.8717L182.466 153.815L187.249 188.452L239.534 181.525L239.699 178.061C234.971 177.841 222.931 166.79 203.578 144.909L203.743 141.445L218.422 128.58C240.194 152.001 261.251 164.811 281.593 167.01L302.375 164.866L305.509 168.66L305.179 175.587C283.737 187.352 272.522 202.582 271.532 221.274L264.275 227.872L258.007 216.986L269.883 190.431L266.419 190.101L189.723 205.77L189.393 212.533L192.692 212.698C276.92 228.642 317.659 259.98 314.91 306.712C312.601 347.176 292.864 369.828 255.698 374.666L252.399 374.501C217.543 372.192 191.648 323.645 174.714 228.862L106.43 217.481C76.8515 252.118 47.1629 268.446 17.3643 266.467L10.6019 265.972L0.870605 255.087L4.49921 252.118L18.189 252.942L21.6526 256.406C46.0633 252.448 69.7592 238.043 92.7404 213.193C54.0353 210.664 34.8476 205.99 35.1775 199.173L53.3205 183.009L60.2479 183.504L111.378 190.266C141.067 153.101 157.396 109.557 160.365 59.6363C169.161 25.8792 175.869 9.16564 180.487 9.49551ZM155.746 139.136C155.087 150.462 144.751 169.704 124.738 196.864L124.573 200.163L166.302 196.369L159.045 139.301L155.746 139.136ZM194.836 234.634L194.671 237.933C210.615 318.642 232.387 359.876 259.986 361.636C283.188 357.457 295.503 342.778 296.932 317.598L297.592 307.701C283.298 262.619 249.046 238.263 194.836 234.634Z" fill="#FB6020"/>
                <path d="M593.225 13.7839L602.131 12.7942L607.245 16.093L612.358 51.3895L614.007 124.621L634.789 267.292C637.978 289.064 651.448 323.81 675.198 371.532C669.701 374.941 661.674 377.305 651.118 378.624L644.19 379.449C641.222 379.779 629.841 359.272 610.048 317.927C589.266 291.977 573.048 275.429 561.392 268.281C563.261 280.817 541.49 294.067 496.077 308.031L496.407 310.01C502.455 309.351 511.581 314.189 523.786 324.525L519.828 328.978C514.22 329.638 503.774 326.284 488.49 318.917L472.821 320.896C467.213 326.834 461.55 330.133 455.832 330.793C449.455 328.923 445.881 325.405 445.112 320.237C468.203 310.89 478.649 298.41 476.45 282.796C479.418 282.356 480.243 277.573 478.924 268.446C482.552 260.09 485.686 255.746 488.325 255.416L490.634 255.087C493.713 254.757 495.527 256.516 496.077 260.364C497.287 268.831 494.483 281.092 487.665 297.145L492.119 296.651C515.21 293.792 528.46 284.885 531.868 269.931C535.497 253.547 549.407 243.871 573.597 240.902L575.742 240.572C586.078 245.52 592.29 251.403 594.379 258.22L596.689 258.055L590.091 213.028L587.617 179.545C573.982 201.097 552.101 213.742 521.972 217.481C492.503 221.11 468.313 186.253 449.4 112.911L447.091 97.4069C444.342 79.1539 447.531 69.4775 456.657 68.378L476.615 63.7597L481.563 67.3884L481.893 101.035C489.04 149.637 506.193 183.339 533.353 202.142L537.971 201.482C558.313 183.779 571.618 154.365 577.886 113.241L569.804 74.3157C564.526 37.9196 567.88 19.0069 579.865 17.5774L593.225 13.7839Z" fill="#FB6020"/>
            </mask>
            <g mask="url(#mask0_79_62)">
                <path className={'char char4'} d="M463.5 62C461.5 94 470 172.2 518 205C566 237.8 581 167 582.5 127.5L571 25L599.5 14C603 18.1667 609.7 36.6 608.5 77C607 127.5 599.5 173 608.5 214.5C615.7 247.7 650.833 334.333 667.5 373.5H644.5C634.5 346 609.6 286.5 590 268.5C565.5 246 552 255.5 550 262C548 268.5 449 330.5 445 331C441 331.5 491.5 287 490.5 255.5C489.5 224 452 276 521 328.5" stroke="#FB6020" strokeWidth="69"/>
            </g>
            <mask id="mask1_79_62" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="255" y="0" width="332" height="329">
                <path d="M583.955 0.588867L586.1 2.0733V3.8876C554.652 11.1448 524.963 20.1614 497.034 30.9373C480.1 37.8646 467.84 52.8189 460.253 75.8001C451.346 99.551 447.773 132.044 449.532 173.278L452.336 190.266L452.666 195.379L453.655 220.945C454.755 247.005 460.693 270.041 471.468 290.053C472.018 301.489 462.452 307.591 442.769 308.361L440.625 308.526C437.327 308.636 431.884 297.475 424.297 275.044L368.218 199.008C361.291 193.95 354.638 186.253 348.261 175.917L346.116 176.082C351.175 186.088 354.308 192.795 355.518 196.204C356.727 199.503 360.081 212.973 365.579 236.614L365.744 240.077V241.727L373.991 283.95C374.98 306.162 363.6 320.786 339.849 327.824L331.437 328.318L322.695 321.886C322.476 314.849 329.953 307.152 345.127 298.795L346.281 279.992L345.952 271.58C343.203 208.134 314.009 150.242 258.37 97.9016L255.896 91.1392C265.242 81.6828 272.83 76.7897 278.657 76.4598C301.638 90.7544 329.403 115.77 361.95 151.506L390.979 187.792L397.742 197.688C404.229 205.276 408.792 210.663 411.431 213.852C414.18 217.041 417.039 220.45 420.008 224.078L422.152 223.913L419.678 164.371L418.689 142.27L420.008 121.653C422.317 110.877 424.681 100.541 427.1 90.6444C443.924 52.2691 461.352 30.5524 479.385 25.4943C512.153 10.5401 547.01 2.23824 583.955 0.588867Z" fill="#FB6020"/>
            </mask>
            <g mask="url(#mask1_79_62)">
                <path className={'char char3'} d="M326.5 324.5C336.167 321.167 355.7 312.6 356.5 305C357.5 295.5 366 257 352 218.5C340.8 187.7 290.333 116 266.5 84C326.5 110.5 459.5 304 459.5 304C447.5 260.167 429.473 140.091 439 97.5C456 21.5 571 4 571 4C574 3.33333 581.1 1.7 585.5 0.5" stroke="#FB6020" strokeWidth="49"/>
            </g>
            <mask id="mask2_79_62" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="35" y="128" width="271" height="101">
                <path  d="M187.249 188.452L239.534 181.525L239.699 178.061C234.971 177.841 222.931 166.79 203.578 144.909L203.743 141.445L218.422 128.58C240.194 152.001 261.251 164.811 281.593 167.01L302.375 164.866L305.509 168.66L305.179 175.587C283.737 187.353 272.522 202.582 271.532 221.275L264.275 227.872L258.007 216.986L269.883 190.431L266.419 190.102L189.723 205.771L174.714 228.862L106.43 217.481L92.7405 213.193C54.0353 210.664 34.8476 205.99 35.1775 199.173L53.3206 183.009L60.2479 183.504L111.378 190.266L124.573 200.163L166.302 196.369L187.249 188.452Z" fill="#FB6020"/>
            </mask>
            <g mask="url(#mask2_79_62)">
                <path className={'char char2'} d="M185 221L36 199.5C115.5 198.833 280.9 191.6 306.5 168C280 171.167 222.5 168.2 204.5 131L308 171C292.667 183.167 261.6 211.8 260 229" stroke="#FB6020" strokeWidth="33"/>
            </g>
            <mask id="mask3_79_62" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="676" height="380">
                <path d="M583.955 0.588867L586.1 2.0733V3.8876C554.652 11.1448 524.963 20.1614 497.034 30.9373C480.1 37.8646 467.84 52.8189 460.253 75.8001C451.346 99.551 447.773 132.044 449.532 173.278L452.336 190.266L452.666 195.379L453.655 220.945C454.755 247.005 460.693 270.041 471.468 290.053C472.018 301.489 462.452 307.591 442.769 308.361L440.625 308.526C437.327 308.636 431.884 297.475 424.297 275.044L368.218 199.008C361.291 193.95 354.638 186.253 348.261 175.917L346.116 176.082C351.175 186.088 354.308 192.795 355.518 196.204C356.727 199.503 360.081 212.973 365.579 236.614L365.744 240.077V241.727L373.991 283.95C374.98 306.162 363.6 320.786 339.849 327.824L331.437 328.318L322.695 321.886C322.476 314.849 329.953 307.152 345.127 298.795L346.281 279.992L345.952 271.58C343.203 208.134 314.009 150.242 258.37 97.9016L255.896 91.1392C265.242 81.6828 272.83 76.7897 278.657 76.4598C301.638 90.7544 329.403 115.77 361.95 151.506L390.979 187.792L397.742 197.688C404.229 205.276 408.792 210.663 411.431 213.852C414.18 217.041 417.039 220.45 420.008 224.078L422.152 223.913L419.678 164.371L418.689 142.27L420.008 121.653C422.317 110.877 424.681 100.541 427.1 90.6444C443.924 52.2691 461.352 30.5524 479.385 25.4943C512.153 10.5401 547.01 2.23824 583.955 0.588867Z" fill="#FB6020"/>
                <path d="M593.225 13.7838L602.131 12.7942L607.245 16.0929L612.358 51.3894L614.007 124.621L634.789 267.292C637.978 289.063 651.448 323.81 675.198 371.532C669.701 374.941 661.674 377.305 651.118 378.624L644.19 379.449C641.222 379.779 629.841 359.272 610.048 317.927C589.266 291.977 573.048 275.429 561.392 268.281C563.261 280.817 541.49 294.067 496.077 308.031L496.407 310.01C502.455 309.351 511.581 314.189 523.786 324.525L519.828 328.978C514.22 329.638 503.774 326.284 488.49 318.917L472.821 320.896C467.213 326.834 461.55 330.133 455.832 330.792C449.455 328.923 445.881 325.405 445.112 320.237C468.203 310.89 478.649 298.41 476.45 282.796C479.418 282.356 480.243 277.573 478.924 268.446C482.552 260.09 485.686 255.746 488.325 255.416L490.634 255.086C493.713 254.757 495.527 256.516 496.077 260.364C497.287 268.831 494.483 281.091 487.665 297.145L492.119 296.651C515.21 293.792 528.46 284.885 531.868 269.931C535.497 253.547 549.407 243.871 573.597 240.902L575.742 240.572C586.078 245.52 592.29 251.403 594.379 258.22L596.689 258.055L590.091 213.028L587.617 179.545C573.982 201.097 552.101 213.742 521.972 217.481C492.503 221.109 468.313 186.253 449.4 112.911L447.091 97.4068C444.342 79.1538 447.531 69.4775 456.657 68.3779L476.615 63.7597L481.563 67.3883L481.893 101.035C489.04 149.637 506.193 183.339 533.353 202.142L537.971 201.482C558.313 183.779 571.618 154.365 577.886 113.241L569.804 74.3156C564.526 37.9196 567.88 19.0068 579.865 17.5774L593.225 13.7838Z" fill="#FB6020"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M183.786 13.1241L180.487 9.49546C175.869 9.16558 169.161 25.8792 160.365 59.6363C157.396 109.557 141.067 153.1 111.378 190.266L92.7404 213.192C69.7592 238.043 46.0633 252.447 21.6526 256.406L18.189 252.942L4.49921 252.118L0.870605 255.086L10.6019 265.972L17.3643 266.467C47.1629 268.446 76.8515 252.118 106.43 217.481L124.738 196.864C144.751 169.704 155.087 150.462 155.746 139.136L159.045 139.301L166.302 196.369L174.714 228.861C191.648 323.645 217.543 372.192 252.399 374.501L255.698 374.666C292.864 369.828 312.601 347.176 314.91 306.712C317.659 259.98 276.92 228.642 192.692 212.698L189.393 212.533L187.249 188.452L182.466 153.815L179.662 84.8716L187.084 13.4539L183.786 13.1241ZM194.836 234.634L194.671 237.933C210.615 318.642 232.387 359.876 259.986 361.636C283.188 357.457 295.503 342.778 296.932 317.598L297.592 307.701C283.298 262.619 249.046 238.263 194.836 234.634Z" fill="#FB6020"/>
            </mask>
            <g mask="url(#mask3_79_62)">
                <path className={'char char1'} d="M2 251C10.1667 257.167 31.1 266.9 49.5 256.5C72.5 243.5 146 186.5 154 124.5C160.4 74.9 173.833 26 179.5 9C174.5 47.3333 165.5 133.9 171.5 163.5C179 200.5 179.5 241 190.5 281C201.5 321 233.5 386.5 266 363.5C298.5 340.5 324 302.5 288.5 261.5C260.1 228.7 206.667 220.5 183.5 220.5" stroke="#FB6020" strokeWidth="28"/>
            </g>
        </svg>
    );
};

export default AnyIcon;