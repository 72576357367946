import React from 'react';
import useFormattedStripEffect from "../../../../../hooks/useFormattedStrip";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import useStagesAnimation from "./useStagesAnimation";
gsap.registerPlugin(ScrollTrigger);
const StagesSection = () => {

    const stripRef1 = useFormattedStripEffect(0.02);

    const { refTarget }= useStagesAnimation();


    return (
        <section ref={refTarget}  className={'stages-section'}  id="stages">
            <div  className="stages-section__inner">
                <div className="stages-section__parallax">
                    <div className="container">
                        <div className="stages-section__wrapper">
                            <div className="stages-section__header">
                                <h2 ref={stripRef1} className="h1 stages-section__h2 strip">Work <span>stages</span></h2>
                            </div>
                        </div>
                    </div>
                    <div  className="stages-section__items">
                        <div className="stages-section__item stages-section__item-orange">
                            <div className="stages-section__item-stage">Stage 1</div>
                            <div className="stages-section__item-title">Organizational unit</div>
                            <div className="stages-section__item-actions">
                                <ul>
                                    <li>1. Sign the contract</li>
                                    <li>2. Create a working chat</li>
                                    <li>3. Get access to the account</li>
                                </ul>
                            </div>
                        </div>
                        <div className="stages-section__item">
                            <div className="stages-section__item-stage">Stage 2</div>
                            <div className="stages-section__item-title">Preparing for the launch</div>
                            <div className="stages-section__item-actions">
                                <ul>
                                    <li>1. Create a strategy </li>
                                    <li>2. Set up analytics </li>
                                    <li>3. Developing reactions</li>
                                </ul>
                            </div>
                        </div>
                        <div className="stages-section__item">
                            <div className="stages-section__item-stage">Stage 3</div>
                            <div className="stages-section__item-title">Launch</div>
                            <div className="stages-section__item-actions">
                                <ul>
                                    <li>1. Launching of the rebranding campaigns </li>
                                    <li>2. Analysis of the first results </li>
                                    <li>3. Scaling up successful relationships</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StagesSection;