import React, {FC} from 'react';

interface IIconBlock{
    title: React.ReactNode;
    children: React.ReactNode
}

const IconBlock:FC<IIconBlock> = ({title,children}) => {
    return (
        <div className={'icon-block'}>
            <div className="icon-block__image">
                {children}
            </div>
            <div className="icon-block__title">{title}</div>
        </div>
    );
};

export default IconBlock;