import Atropos from 'atropos/react';
import React, {useLayoutEffect, useRef} from 'react';
import CardNumber from "../../../../other/CardNumber";
import useFormattedStripEffect from "../../../../../hooks/useFormattedStrip";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import useAchievementsAnimation from "./useAchievementsAnimation";
gsap.registerPlugin(ScrollTrigger);
const AchievementsSection = () => {

    const stripRef1 = useFormattedStripEffect(0.02);

    const {refTarget} = useAchievementsAnimation();

    return (
        <section ref={refTarget} className={'achievements'}  id="achievements">
            <div className="container">
                <div className="achievements__wrapper">
                    <h2 ref={stripRef1} className="h2 achievements__h2 strip">
                        <span>
                            Achievements
                        </span>
                        <span className="achievements__header">of the <span>lyudy</span>.Digital</span>
                    </h2>
                    <div className="achievements__inner">
                        <div className="achievements__items">
                            <div className="achievements__item">
                                <Atropos>
                                    <CardNumber title={'1'} description={<>office <br/>in Kyiv</>} />
                                </Atropos>

                            </div>
                            <div className="achievements__item">
                                <Atropos>
                                    <CardNumber className={'card-number-orange'} title={'1.4 mln'} description={<>of the repair budget was invested in <br/>a difficult 2022 for Ukrainian business</>}/>
                                </Atropos>
                            </div>
                            <div className="achievements__item">
                                <Atropos>
                                    <CardNumber title={'8 months'} description={<>average duration <br/>of cooperation</>} />
                                </Atropos>
                            </div>
                            <div className="achievements__item">
                                <Atropos>
                                    <CardNumber title={'15'} description={<>specialists in <br/>the team</>} />
                                </Atropos>
                            </div>
                            <div className="achievements__item">
                                <Atropos>
                                    <CardNumber title={'300+'} description={<>of implemented <br/>projects</>} />
                                </Atropos>
                            </div>
                            <div className="achievements__item">
                                <Atropos>
                                    <CardNumber title={'77'} description={<>active projects <br/>during the war</>} />
                                </Atropos>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default AchievementsSection;