import React from 'react';
import Button from "../sections/atoms/Button";
import {useSelectorEx} from "../../hooks/redux";

const MenuModal = () => {
    const {modalMenu} = useSelectorEx(state => state.app)
    return (
        <div className={`menu-modal ${modalMenu ? "active": ""}`}>
            <div className="menu-modal__decorator">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="menu-modal__wrapper">
                <div className="container">
                    <div className="menu-modal__inner">
                        <div className="menu-modal__menu">
                            <ul>
                                <li><a href="#">About ЛЮДИ.digital<span>About ЛЮДИ.digital</span></a></li>
                                <li><a href="#">Cases<span>Cases</span></a></li>
                                <li><a href="#">Team<span>Team</span></a></li>
                                <li><a href="#">Work stages<span>Work stages</span></a></li>
                                <li><a href="#">Consultation<span>Consultation</span></a></li>
                                <li><Button className={'button-orange'} name={'Contact us'} effect={'bubble'}/></li>
                            </ul>
                        </div>
                        <div className="menu-modal__multilines">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuModal;