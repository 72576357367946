import React from 'react';

const DeliveryIcon = () => {
    return (
        <svg width="76" height="80" viewBox="0 0 76 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.3872 64.6094C29.3872 67.0958 28.3995 69.4804 26.6413 71.2385C24.8832 72.9967 22.4987 73.9844 20.0122 73.9844C17.5258 73.9844 15.1413 72.9967 13.3831 71.2385C11.6249 69.4804 10.6372 67.0958 10.6372 64.6094" stroke="white" strokeWidth="1.76471" strokeLinejoin="round"/>
            <path d="M50.48 64.6094C50.48 67.0958 51.4677 69.4804 53.2259 71.2385C54.984 72.9967 57.3685 73.9844 59.855 73.9844C62.3414 73.9844 64.7259 72.9967 66.4841 71.2385C68.2423 69.4804 69.23 67.0958 69.23 64.6094" stroke="white" strokeWidth="1.76471" strokeLinejoin="round"/>
            <path d="M24.6978 64.6094C24.6978 65.8526 24.2039 67.0448 23.3248 67.9239C22.4457 68.803 21.2535 69.2969 20.0103 69.2969C18.7671 69.2969 17.5748 68.803 16.6957 67.9239C15.8166 67.0448 15.3228 65.8526 15.3228 64.6094" stroke="white" strokeWidth="1.76471" strokeLinejoin="round"/>
            <path d="M64.5425 64.6094C64.5425 65.8526 64.0486 67.0448 63.1695 67.9239C62.2904 68.803 61.0981 69.2969 59.855 69.2969C58.6117 69.2969 57.4195 68.803 56.5404 67.9239C55.6613 67.0448 55.1675 65.8526 55.1675 64.6094" stroke="white" strokeWidth="1.76471" strokeLinejoin="round"/>
            <path d="M50.4795 38.8281C49.8579 38.8281 49.2618 39.075 48.8222 39.5146C48.3827 39.9541 48.1357 40.5502 48.1357 41.1718C51.3701 41.1718 53.9952 45.8593 53.9952 51.7187C53.9952 56.2539 51.3701 58.75 48.1357 58.75H41.1045C37.2256 58.75 34.0732 56.125 34.0732 52.8906C34.0732 49.6563 37.2256 47.0313 41.1045 47.0313V44.6875H18.8389C10.4248 44.6875 3.60449 53.0781 3.60449 63.4375V64.6093H50.4795C55.6592 64.6093 59.8545 58.8437 59.8545 51.7187C59.8545 44.5938 55.6592 38.8281 50.4795 38.8281Z" stroke="white" strokeWidth="1.76471" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.60449 44.6875H18.8389" stroke="white" strokeWidth="1.76471" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.12012 64.6089C7.12012 56.8394 12.8857 50.5464 20.0107 50.5464C27.1357 50.5464 32.9014 56.8394 32.9014 64.6089" stroke="white" strokeWidth="1.76471" strokeLinejoin="round"/>
            <path d="M48.1367 64.6089H71.5742C71.5742 61.8117 70.4631 59.129 68.4851 57.1511C66.5072 55.1732 63.8246 54.062 61.0274 54.062H59.8555" stroke="white" strokeWidth="1.76471" strokeLinejoin="round"/>
            <path d="M59.8545 29.4536H58.6826C54.8037 29.4536 51.6514 31.5512 51.6514 34.1411C51.6514 36.7309 54.8037 38.8286 58.6826 38.8286H59.8545" stroke="white" strokeWidth="1.76471" strokeLinejoin="round"/>
            <path d="M59.8545 38.8286C61.1489 38.8286 62.1982 36.7299 62.1982 34.1411C62.1982 31.5523 61.1489 29.4536 59.8545 29.4536C58.5601 29.4536 57.5107 31.5523 57.5107 34.1411C57.5107 36.7299 58.5601 38.8286 59.8545 38.8286Z" stroke="white" strokeWidth="1.76471" strokeLinejoin="round"/>
            <path d="M48.1367 41.1713V36.4838C48.1367 35.8622 48.3837 35.2661 48.8232 34.8266C49.2628 34.387 49.8589 34.1401 50.4805 34.1401H51.6524" stroke="white" strokeWidth="1.76471" strokeLinejoin="round"/>
            <path d="M53.9956 34.1401H45.7925" stroke="white" strokeWidth="1.76471" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.4951 59.9224H23.5263" stroke="white" strokeWidth="1.76471" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.8398 55.2344H21.1847" stroke="white" strokeWidth="1.76471" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.1529 44.688C13.5313 44.688 12.9351 44.441 12.4956 44.0015C12.0561 43.562 11.8091 42.9658 11.8091 42.3442C11.8091 41.7227 12.0561 41.1265 12.4956 40.687C12.9351 40.2475 13.5313 40.0005 14.1529 40.0005H37.5904C38.212 40.0005 38.8081 40.2475 39.2477 40.687C39.6872 41.1265 39.9341 41.7227 39.9341 42.3442C39.9341 42.9658 39.6872 43.562 39.2477 44.0015C38.8081 44.441 38.212 44.688 37.5904 44.688H14.1529Z" fill="white"/>
            <path d="M11.8091 36.4849V35.313C11.8091 32.205 13.0438 29.2244 15.2414 27.0266C17.4392 24.8289 20.4199 23.5943 23.5279 23.5942C26.6359 23.5942 29.6166 24.8289 31.8143 27.0266C34.012 29.2244 35.2467 32.205 35.2467 35.313V36.4849" stroke="white" strokeWidth="1.76471" strokeMiterlimit="10"/>
            <path d="M8.29199 36.4849H38.7607" stroke="white" strokeWidth="1.76471" strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M21.1836 23.5942V22.4223C21.1836 21.8007 21.4306 21.2046 21.8701 20.7651C22.3097 20.3255 22.9058 20.0786 23.5274 20.0786C24.149 20.0786 24.7451 20.3255 25.1847 20.7651C25.6242 21.2046 25.8712 21.8007 25.8712 22.4223V23.5942" stroke="white" strokeWidth="1.76471" strokeMiterlimit="10"/>
            <path d="M23.5259 20.0788V17.7339" stroke="white" strokeWidth="1.76471" strokeMiterlimit="10" strokeLinecap="round"/>
        </svg>
    );
};

export default DeliveryIcon;