import React, {useLayoutEffect, useRef} from 'react';
import TelegramIcon from "../../../../icons/social/TelegramIcon";
import InstaIcon from "../../../../icons/social/InstaIcon";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Footer = () => {

    const refTarget = useRef(null);
    useLayoutEffect(()=>{
        const timeline = gsap.timeline()
        setTimeout(() => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: refTarget.current,
                    scroller: ".scroll-container",
                },
            })
                .fromTo('.footer__social a',{
                        opacity:0,
                        duration: 1,
                        delay: 0.1,
                    },
                    {
                        opacity:1,
                        duration: 1,
                        delay: 0.1,
                    },
                    0.5
                )
                .fromTo('.footer-social-header',{
                        opacity:0,
                        duration: 1,
                        delay: 0.1,
                    },
                    {
                        opacity:1,
                        duration: 1,
                        delay: 0.1,
                    },
                    0.5
                )

                .fromTo('.footer__description',{
                    opacity:0,
                    duration: 1,
                    delay: 0.1,
                },
                {
                    opacity:1,
                    duration: 1,
                    delay: 0.1,
                },
                0.5
            )
            ScrollTrigger.refresh();
        }, 1000);

        ScrollTrigger.refresh();

        return () => {
            timeline.kill();
            ScrollTrigger.getAll().forEach(st => st.kill())
        };
    },[refTarget])//data-scroll-section
    return (
        <footer ref={refTarget} className={'footer'} id="footer">
            <div className="container">
                <div className="footer__wrapper">
                    <div className="footer__description">We are ready to meet with you in person in Kyiv</div>
                    <div className="footer__social">
                        <span className="footer-social-header">Follow us on social media</span>
                        <ul>
                            <li><a href="src/components/sections#"><TelegramIcon/></a></li>
                            <li><a href="src/components/sections"><InstaIcon/></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;