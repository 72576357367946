export function wrapLettersInElements(text: any) {
    const wrapper = document.createElement('div');
    const parser = new DOMParser();
    const parsedText = parser.parseFromString(text, 'text/html').body;

    let lineRow: any = null; // Ссылка на текущий .line-row

    function wrapNodes(node: any, parent: any) {
        if (node.nodeType === Node.TEXT_NODE) {
            const letters = node.textContent.split('');
            letters.forEach((letter: any) => {
                const letterElement = document.createElement('div');
                letter = letter === ' ' ? String.fromCharCode(160) : letter;
                letterElement.textContent = letter;

                // Проверяем, нужно ли создать новый .line-row
                if (!lineRow) {
                    lineRow = document.createElement('div');
                    lineRow.className = 'line-row';
                    parent.appendChild(lineRow);
                }

                lineRow.appendChild(letterElement);
            });
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            if (node.tagName.toLowerCase() === 'br') {
                lineRow = null; // Сбрасываем ссылку на .line-row при встрече тега <br>
            } else {
                const element = document.createElement(node.tagName);
                element.className = node.className;
                parent.appendChild(element);

                if (node.tagName.toLowerCase() === 'span') {
                    element.className = 'line-row';
                    lineRow = element; // Обновляем ссылку на текущий .line-row
                }

                if (node.childNodes.length > 0) {
                    wrapNodes(node.childNodes[0], element);
                }
            }
        }

        if (node.nextSibling) {
            wrapNodes(node.nextSibling, parent);
        }
    }

    wrapNodes(parsedText, wrapper);
    return wrapper.innerHTML;
}
