import React from 'react';

const InfoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 75 80">
            <path fill="#fefefe" d="
  M 75.00 65.56
  L 75.00 72.31
  L 0.00 72.26
  L 0.00 65.44
  Q 0.82 64.65 3.02 64.92
  A 1.11 1.11 -86.6 0 0 4.26 63.82
  L 4.26 26.05
  A 1.21 1.10 89.0 0 1 5.32 24.84
  L 10.53 24.67
  A 0.45 0.43 -2.5 0 0 10.97 24.21
  Q 10.88 23.03 11.33 22.01
  A 1.51 1.23 -7.2 0 1 11.98 21.33
  L 36.25 8.05
  A 1.26 1.25 -45.2 0 1 37.43 8.04
  L 62.53 21.28
  A 1.57 1.22 7.0 0 1 63.21 21.96
  Q 63.69 23.04 63.59 24.25
  A 0.44 0.38 3.1 0 0 64.02 24.67
  L 69.50 24.80
  A 1.52 1.28 -89.4 0 1 70.75 26.31
  L 70.75 63.98
  A 1.13 1.13 -8.7 0 0 72.22 65.06
  Q 73.83 64.56 75.00 65.56
  Z
  M 36.29 23.62
  Q 36.68 23.02 37.36 22.64
  A 0.69 0.63 53.0 0 1 38.16 22.79
  L 45.98 30.93
  A 0.65 0.63 -36.3 0 0 46.76 31.04
  L 59.72 23.71
  Q 60.51 23.27 59.73 22.86
  L 37.51 11.14
  A 1.60 1.56 -45.0 0 0 36.00 11.16
  L 14.33 23.07
  Q 14.02 23.24 14.32 23.42
  L 36.30 36.32
  A 0.93 0.79 43.0 0 0 37.18 36.36
  L 43.39 32.77
  A 0.41 0.39 53.4 0 0 43.46 32.15
  L 36.39 24.45
  A 0.71 0.66 40.2 0 1 36.29 23.62
  Z
  M 48.12 53.56
  L 47.90 53.48
  A 1.00 0.38 -42.0 0 0 47.15 53.77
  Q 45.30 55.19 43.40 53.76
  A 0.77 0.68 -65.3 0 1 43.16 52.96
  L 44.74 47.46
  Q 45.19 45.85 43.58 46.32
  C 31.85 49.70 18.67 44.94 19.76 30.46
  A 1.02 0.96 -68.3 0 0 19.43 29.63
  C 15.95 26.57 12.77 26.98 7.33 27.39
  A 0.55 0.54 -2.3 0 0 6.82 27.93
  L 6.88 64.40
  A 0.51 0.51 0.3 0 0 7.39 64.91
  L 67.62 64.91
  A 0.51 0.51 0.0 0 0 68.13 64.40
  L 68.18 27.90
  A 0.55 0.53 2.3 0 0 67.67 27.37
  C 62.38 27.06 59.33 26.60 54.70 29.56
  A 0.85 0.78 71.7 0 0 54.35 30.33
  Q 55.28 39.55 47.81 44.61
  A 1.06 0.99 -16.5 0 0 47.35 45.39
  C 47.14 48.47 48.80 49.55 50.10 52.05
  A 0.60 0.49 62.0 0 1 49.95 52.81
  L 48.53 53.62
  A 0.51 0.09 18.8 0 1 48.12 53.56
  Z
  M 47.35 40.27
  Q 47.36 41.86 48.47 40.82
  Q 51.96 37.56 51.68 31.90
  A 0.35 0.33 75.1 0 0 51.19 31.59
  L 47.62 33.63
  A 1.00 0.55 82.9 0 0 47.30 34.43
  L 47.35 40.27
  Z
  M 36.37 39.21
  L 22.95 31.51
  A 0.43 0.43 17.5 0 0 22.30 31.84
  C 21.39 44.21 35.29 46.21 44.03 43.36
  A 0.80 0.78 85.5 0 0 44.57 42.71
  Q 45.07 39.27 44.56 35.77
  A 0.34 0.33 -19.2 0 0 44.06 35.54
  L 37.28 39.23
  A 0.96 0.93 45.1 0 1 36.37 39.21
  Z
  M 72.42 68.23
  A 0.70 0.70 0.0 0 0 71.72 67.53
  L 3.26 67.53
  A 0.70 0.70 0.0 0 0 2.56 68.23
  L 2.56 69.55
  A 0.70 0.70 0.0 0 0 3.26 70.25
  L 71.72 70.25
  A 0.70 0.70 0.0 0 0 72.42 69.55
  L 72.42 68.23
  Z"
            />
        </svg>
    );
};

export default InfoIcon;