import {useRef, useLayoutEffect, useCallback} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useSelectorEx} from "../../../../../hooks/redux";

gsap.registerPlugin(ScrollTrigger);

const useMarqueeAnimation = () => {

    const refTarget = useRef(null);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const animationLarge = (timeline:any) => {

        timeline.to(refTarget.current, {
            scrollTrigger: {
                id: "useMarqueeAnimation",
                trigger: refTarget.current,
                scroller: ".scroll-container",
                pin: false,
                start: "top bottom",
                end: "150% bottom",
                onUpdate: (self: any) => {
                    gsap.to(ref1.current, { transform: `translateX(-${30*self.progress}%)` });
                    gsap.to(ref2.current, { transform: `translateX(${-30 + 60*self.progress}%)` });
                },
            },
            ease: "none",
        });
    };

    const animationMob = (timeline:any) => {
        timeline.to(refTarget.current, {
            scrollTrigger: {
                id: "useMarqueeAnimation",
                trigger: refTarget.current,
                scroller: ".scroll-container",
                pin: false,
                start: "top bottom",
                end: "200% bottom",
                onUpdate: (self: any) => {
                    gsap.to(ref1.current, { transform: `translateX(-${30*self.progress}%)` });
                    gsap.to(ref2.current, { transform: `translateX(${-30 + 60*self.progress}%)` });
                },
            },
            ease: "none",
        });
    };

    const {preloader,preloaderStatus} = useSelectorEx(state => state.app)

    useLayoutEffect(() => {
        if(!preloader && preloaderStatus >= 100) {
            const timeline = gsap.timeline();


            const animate = () => {
                let screenWidth:any = window.innerWidth;
                if (screenWidth >= 768) {
                    animationLarge(timeline);
                } else {
                    animationMob(timeline);
                }
            };

            animate();

            const handleResize = () => {
                if(ScrollTrigger.getAll().length >= 1) {
                    ScrollTrigger.getById("useMarqueeAnimation")?.kill(true);
                }
                timeline.kill();
                timeline.clear();
                animate();
            };

            window.addEventListener('resize', handleResize);

            return () => {
                timeline.kill();
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [preloader,preloaderStatus]);

    return {
        refTarget,
        ref1,
        ref2,
    };
};

export default useMarqueeAnimation;


