import React, {FC} from 'react';
import Button from "../sections/atoms/Button";

interface ICardBlock{
    className?: string;
    image: React.ReactNode;
    title:React.ReactNode;
    description:React.ReactNode;
    button:string;
    onClick:()=>void;
}

const CardBlock:FC<ICardBlock> = ({className='',image,title,description,button,onClick}) => {
    return (
        <div className={`card-block ${className}`}>
            <div className="card-block__image">{image}</div>
            <div className="card-block__title">{title}</div>
            <div className="card-block__description">{description}</div>
            <div className="card-block__button">
                <Button effect={'bubble'} className={'button-white'} onClick={onClick} name={button} />
            </div>
        </div>
    );
};

export default CardBlock;