import React, {FC} from 'react';


interface ICardNumber{
    className?: string;
    title: string;
    description: React.ReactNode;
}

const CardNumber:FC<ICardNumber> = ({className='',title,description}) => {
    return (
        <div className={`card-number ${className}`}>
            <div className="card-number__title">{title}</div>
            <div className="card-number__description">{description}</div>
        </div>
    );
};

export default CardNumber;