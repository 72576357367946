import React, {useEffect, useState} from 'react';
import {useSelectorEx} from "../hooks/redux";
import gsap from "gsap";
const Preloader = () => {
    const [number, setNumber] = useState<number>(0);
    const { preloaderStatus } = useSelectorEx(state => state.app);

    useEffect(() => {

        const animation = gsap.fromTo(".preloader__loading span",
            {
                innerHTML: Math.floor(number).toFixed(2),
            },
            {
                onUpdate: function() {
                    // @ts-ignore
                    this.targets()[0].innerHTML = Math.ceil(this.targets()[0].textContent);
                    setNumber(Math.ceil(this.targets()[0].textContent));
                },
                // Конечное состояние числа
                innerText: Math.floor(preloaderStatus).toFixed(2), // Конечное число с знаком процента
                duration: 2,
                ease: 'power2.out', // Вы можете выбрать другую функцию по желанию
            },
          );

        return () => {
            animation.kill();
        };
    }, [preloaderStatus]);
    return (
        <div className={'preloader'}>
            <div className="preloader__loading"><span></span> %</div>
            <div className="preloader__line" style={{width:`${number}%`}}></div>
        </div>
    );
};

export default Preloader;