import React from 'react';

const ArrowIcon = () => {
    return (
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.4109 21.8741C27.4228 21.8741 26.6143 21.0655 26.6143 20.0772V4.09405H10.6238C9.63565 4.09405 8.82715 3.28545 8.82715 2.29718C8.82715 1.3089 9.63565 0.500305 10.6238 0.500305H28.4019C29.3901 0.500305 30.1986 1.3089 30.1986 2.29718V20.0772C30.2076 21.0745 29.3991 21.8741 28.4109 21.8741Z" fill="white"/>
            <path d="M0.71449 29.9855C0.00474769 29.2937 0.0137318 28.1437 0.71449 27.4429L21.0455 7.11134C21.7462 6.41056 22.8872 6.41056 23.5879 7.11134C24.2887 7.81212 24.2887 8.95313 23.5879 9.65391L3.25698 29.9855C2.69099 30.5785 1.50509 30.7582 0.71449 29.9855Z" fill="white"/>
        </svg>
    );
};

export default ArrowIcon;