import React from 'react';

const FranchiseIcon = () => {
    return (
        <svg width="131" height="80" viewBox="0 0 131 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_155_107)">
                <path d="M85.1178 2.66895H75.8984L77.0532 14.2172C77.0532 14.2172 79.3629 16.5268 82.8274 16.5268C85.3148 16.5268 87.0084 15.336 87.766 14.6645C88.0679 14.3969 88.1783 13.9883 88.112 13.5904L86.4847 3.8269C86.3733 3.1587 85.7952 2.66895 85.1178 2.66895Z" stroke="white" strokeWidth="2.63514"/>
                <path d="M75.8939 2.66895L77.0487 14.2172C77.0487 14.2172 74.7391 16.5268 71.2746 16.5268C67.8102 16.5268 65.5005 14.2172 65.5005 14.2172V2.66895H75.8939Z" stroke="white" strokeWidth="2.63514"/>
                <path d="M65.5028 2.66895V14.2172C65.5028 14.2172 63.1931 16.5268 59.7287 16.5268C56.2642 16.5268 53.9546 14.2172 53.9546 14.2172L55.1094 2.66895H65.5028Z" stroke="white" strokeWidth="2.63514"/>
                <path d="M55.108 2.66895H45.8885C45.2111 2.66895 44.633 3.1587 44.5216 3.8269L42.8943 13.5904C42.828 13.9883 42.9385 14.3969 43.2404 14.6645C43.9977 15.336 45.6914 16.5268 48.179 16.5268C51.6435 16.5268 53.9531 14.2172 53.9531 14.2172L55.108 2.66895Z" stroke="white" strokeWidth="2.63514"/>
                <path d="M44.7302 16.5278V39.6242C44.7302 42.1754 46.7984 44.2435 49.3495 44.2435H81.6845C84.2356 44.2435 86.3037 42.1754 86.3037 39.6242V16.5278" stroke="white" strokeWidth="2.63514"/>
                <path d="M26.3484 57.992C26.3484 64.7753 14.4076 76.4149 14.4076 76.4149C14.4076 76.4149 2.4668 64.7753 2.4668 57.992C2.4668 51.2088 7.81289 45.71 14.4076 45.71C21.0023 45.71 26.3484 51.2088 26.3484 57.992Z" stroke="white" strokeWidth="2.63514"/>
                <path d="M34.6417 37.2453C35.3188 37.5128 36.0846 37.1808 36.3521 36.5037C36.6197 35.8267 36.2878 35.0609 35.6107 34.7933C34.9336 34.5257 34.1678 34.8577 33.9002 35.5348C33.6326 36.2119 33.9647 36.9777 34.6417 37.2453Z" fill="white"/>
                <mask id="mask0_155_107"  maskUnits="userSpaceOnUse" x="33" y="34" width="4" height="4">
                    <path d="M34.638 37.2462C35.3151 37.5138 36.0809 37.1818 36.3484 36.5047C36.616 35.8277 36.2841 35.0619 35.607 34.7943C34.93 34.5267 34.1642 34.8587 33.8966 35.5357C33.629 36.2129 33.961 36.9786 34.638 37.2462Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_155_107)">
                    <path d="M33.6706 39.6948C35.7009 40.4971 37.9972 39.5017 38.7995 37.4714L33.8981 35.5344C34.1655 34.8577 34.9309 34.5258 35.6076 34.7933L33.6706 39.6948ZM38.7995 37.4714C39.6018 35.4412 38.6064 33.1449 36.5761 32.3426L34.6391 37.2441C33.9624 36.9766 33.6306 36.2112 33.8981 35.5344L38.7995 37.4714ZM36.5761 32.3426C34.5459 31.5403 32.2497 32.5357 31.4474 34.5659L36.3487 36.5029C36.0813 37.1797 35.3159 37.5115 34.6391 37.2441L36.5761 32.3426ZM31.4474 34.5659C30.6451 36.5962 31.6404 38.8924 33.6706 39.6948L35.6076 34.7933C36.2844 35.0608 36.6162 35.8262 36.3487 36.5029L31.4474 34.5659Z" fill="white"/>
                </g>
                <path d="M27.6217 42.5006C28.2987 42.7681 29.0645 42.4362 29.3321 41.7591C29.5997 41.082 29.2677 40.3162 28.5907 40.0486C27.9135 39.7811 27.1477 40.1131 26.8802 40.7901C26.6126 41.4672 26.9446 42.233 27.6217 42.5006Z" fill="white"/>
                <mask id="mask1_155_107"  maskUnits="userSpaceOnUse" x="26" y="39" width="4" height="4">
                    <path d="M27.6244 42.5006C28.3014 42.7681 29.0672 42.4362 29.3348 41.7591C29.6024 41.082 29.2704 40.3162 28.5933 40.0486C27.9162 39.7811 27.1504 40.1131 26.8829 40.7901C26.6153 41.4672 26.9472 42.233 27.6244 42.5006Z" fill="white"/>
                </mask>
                <g mask="url(#mask1_155_107)">
                    <path d="M26.6551 44.9491C28.6853 45.7515 30.9816 44.7561 31.7839 42.7258L26.8825 40.7888C27.1499 40.1121 27.9153 39.7803 28.5921 40.0477L26.6551 44.9491ZM31.7839 42.7258C32.5862 40.6956 31.5908 38.3993 29.5606 37.597L27.6236 42.4985C26.9468 42.231 26.615 41.4656 26.8825 40.7888L31.7839 42.7258ZM29.5606 37.597C27.5303 36.7947 25.2341 37.7901 24.4317 39.8203L29.3332 41.7573C29.0657 42.4341 28.3003 42.7659 27.6236 42.4985L29.5606 37.597ZM24.4317 39.8203C23.6294 41.8506 24.6248 44.1468 26.6551 44.9491L28.5921 40.0477C29.2688 40.3152 29.6007 41.0806 29.3332 41.7573L24.4317 39.8203Z" fill="white"/>
                </g>
                <path d="M95.8364 38.3012C95.1817 38.6197 94.3925 38.3473 94.0744 37.6927C93.7554 37.038 94.0278 36.249 94.6825 35.9306C95.3372 35.612 96.1264 35.8844 96.4445 36.5391C96.7635 37.1938 96.4911 37.9827 95.8364 38.3012Z" fill="white"/>
                <mask id="mask2_155_107"  maskUnits="userSpaceOnUse" x="93" y="35" width="4" height="4">
                    <path d="M95.8379 38.3003C95.1832 38.6187 94.394 38.3463 94.0759 37.6917C93.7569 37.0371 94.0293 36.2481 94.684 35.9296C95.3387 35.611 96.1279 35.8834 96.446 36.5382C96.765 37.1928 96.4926 37.9817 95.8379 38.3003Z" fill="white"/>
                </mask>
                <g mask="url(#mask2_155_107)">
                    <path d="M96.9908 40.6685C95.0276 41.6237 92.6622 40.8066 91.7065 38.8437L96.4453 36.5378C96.1274 35.8834 95.3386 35.6111 94.6842 35.9295L96.9908 40.6685ZM91.7065 38.8437C90.7517 36.8807 91.5686 34.5151 93.5318 33.56L95.8375 38.299C96.4919 37.9806 96.7642 37.1921 96.4453 36.5378L91.7065 38.8437ZM93.5318 33.56C95.4941 32.6048 97.8604 33.4218 98.8152 35.3847L94.0764 37.6907C94.3943 38.345 95.1831 38.6173 95.8375 38.299L93.5318 33.56ZM98.8152 35.3847C99.77 37.3477 98.9531 39.7134 96.9908 40.6685L94.6842 35.9295C94.0298 36.2478 93.7575 37.0363 94.0764 37.6907L98.8152 35.3847Z" fill="white"/>
                </g>
                <path d="M103.234 43.0053C102.579 43.3239 101.79 43.0514 101.472 42.3968C101.153 41.7421 101.425 40.9532 102.08 40.6346C102.735 40.3161 103.524 40.5886 103.842 41.2432C104.161 41.8979 103.889 42.6868 103.234 43.0053Z" fill="white"/>
                <mask id="mask3_155_107"  maskUnits="userSpaceOnUse" x="101" y="40" width="3" height="4">
                    <path d="M103.238 43.0048C102.583 43.3234 101.794 43.0509 101.476 42.3963C101.157 41.7416 101.429 40.9527 102.084 40.6341C102.738 40.3156 103.528 40.5881 103.846 41.2427C104.165 41.8974 103.892 42.6863 103.238 43.0048Z" fill="white"/>
                </mask>
                <g mask="url(#mask3_155_107)">
                    <path d="M104.389 45.3726C102.427 46.3278 100.061 45.5107 99.1059 43.5478L103.845 41.2418C103.527 40.5875 102.738 40.3152 102.084 40.6335L104.389 45.3726ZM99.1059 43.5478C98.1511 41.5848 98.968 39.2193 100.931 38.2641L103.237 43.0031C103.891 42.6847 104.164 41.8962 103.845 41.2418L99.1059 43.5478ZM100.931 38.2641C102.893 37.3089 105.259 38.1259 106.215 40.0889L101.476 42.3949C101.794 43.0492 102.583 43.3215 103.237 43.0031L100.931 38.2641ZM106.215 40.0889C107.169 42.0519 106.353 44.4174 104.389 45.3726L102.084 40.6335C101.429 40.952 101.157 41.7405 101.476 42.3949L106.215 40.0889Z" fill="white"/>
                </g>
                <path d="M129.12 56.2361C129.12 63.0193 117.18 74.659 117.18 74.659C117.18 74.659 105.239 63.0193 105.239 56.2361C105.239 49.4529 110.585 43.9541 117.18 43.9541C123.775 43.9541 129.12 49.4529 129.12 56.2361Z" stroke="white" strokeWidth="2.63514"/>
            </g>
            <defs>
                <clipPath id="clip0_155_107">
                    <rect width="130" height="78.1757" fill="white" transform="translate(0.713623 0.913086)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default FranchiseIcon;