import React, {useLayoutEffect, useRef} from 'react';
import Button from "../../../atoms/Button";
import Input from "../../../atoms/Input";
import useFormattedStripEffect from "../../../../../hooks/useFormattedStrip";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import useContactAnimation from "./useContactAnimation";
gsap.registerPlugin(ScrollTrigger);
const ContactSection = () => {
    const stripRef1 = useFormattedStripEffect(0.02);
    const stripRef2 = useFormattedStripEffect(0.02);
    const stripRef3 = useFormattedStripEffect(0.02);

    const {refTarget} = useContactAnimation();
    return (
        <section  className={'contact-section'}  id="contact">
            <div className="container">
                <div className="contact-section__wrapper">
                    <div className="contact-section__header-wrapper">
                        <h2 ref={stripRef1} className="h1 strip">Contact</h2>
                        <div ref={stripRef3} className={'contact-section__add-mob contact-section__add h1 strip'}><span>us</span></div>
                    </div>

                    <div className="contact-section__inner">
                        <div ref={stripRef2} className={'contact-section__add h1 strip'}><span>us</span></div>
                        <div ref={refTarget} className="contact-section__form">
                            <div className="contact-section__input">
                                <Input name={'name1'} placeholder={'Your Name'} />
                            </div>
                            <div className="contact-section__input">
                                <Input name={'phone1'} placeholder={'Phone Number'} />
                            </div>
                            <div className="contact-section__input">
                                <Input name={'link1'} placeholder={'Link To The Website/Instagram'} />
                            </div>
                            <div className="contact-section__button">
                                <Button effect={'bubble'} className={'button-white button-big'} name={'SUBMIT'} />
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    );
};

export default ContactSection;