import React, {FC} from 'react';
import {Link} from "react-router-dom";
import useBubbleHover from "../../../hooks/useBubbleHover";

interface IButton {
    type?: 'link' | 'button' | "htmlLink";
    name: string;
    className?: string;
    onClick?: ()=>void
    href?:string;
    effect?: "bubble" | ""
}

const Button:FC<IButton> = ({effect="",type="link",name,className='', onClick, href}) => {
    const ref = useBubbleHover();
    return (
        type === 'htmlLink' ? <a ref={ref}
                                 onClick={onClick}
                                 className={`button ${className}`}
                                 target={"_blank"} href={href as string}>
                <span className="button__name">{name}</span>
                {effect && <span className="button__effect"></span>}
        </a>
            :
            type === 'button' ?
                <button ref={ref} type={'submit'} onClick={onClick} className={`button ${className}`}>
                    <span className="button__name">{name}</span>
                    {effect && <span className="button__effect"></span>}
                </button>
                :
                <Link ref={ref} onClick={onClick} className={`button ${className}`} to={href as string}>
                    <span className="button__name">{name}</span>
                    {effect && <span className="button__effect"></span>}
                </Link>
    );
};

export default Button;