import React, { useEffect, useRef } from 'react';

interface HoverImageProps {
    imageUrl: string;
    children: React.ReactNode;
}

const RevealImage: React.FC<HoverImageProps> = ({ imageUrl, children }) => {
    const imageRef = useRef<HTMLImageElement | null>(null);
    const blockRef = useRef<HTMLDivElement | null>(null);
    const timeRef = useRef<any>(new Date().getTime());
    const lastXRef = useRef<any>(new Date().getTime());
    const offset = 100; // Adjust this value to control the distance between the cursor and the image

    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            const block = blockRef.current;
            const image = imageRef.current;

            if(timeRef.current < new Date().getTime()){
                if (block && image) {
                    const blockRect = block.getBoundingClientRect();
                    const blockWidth = blockRect.width;
                    const blockHeight = blockRect.height;
                    const mouseX = event.clientX - blockRect.left;
                    const mouseY = event.clientY - blockRect.top;

                    if (mouseX >= 0 && mouseX <= blockWidth && mouseY >= 0 && mouseY <= blockHeight) {
                        image.style.display = 'block';
                        image.style.left = `${mouseX - offset}px`;
                        image.style.top = `${mouseY - offset}px`;
                        if(lastXRef.current < mouseX - offset){//left
                            image.style.transform = `rotate(330deg)`;
                        }
                        if(lastXRef.current > mouseX - offset){//right
                            image.style.transform = `rotate(370deg)`;
                        }
                        lastXRef.current = mouseX - offset;

                    } else {
                        image.style.display = 'none';
                    }
                }
                timeRef.current = new Date().getTime()+100;

            }


        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);


    return (
        <div className="block" ref={blockRef} style={{ position: 'relative', width: '100%' }}>
            {children}
            <img
                ref={imageRef}
                src={imageUrl}
                alt="Your Image"
                className="hover-image"
                style={{ position: 'absolute', display: 'none', transformOrigin: 'center', transform: "rotate(360deg)" }}
            />
        </div>
    );
};

export default RevealImage;