import React, { useEffect, useRef } from 'react';
import CalligraphyIcon from "../../../../icons/CalligraphyIcon";
import MultiLine from '../../../atoms/MultiLine';
import AnyIcon from "../../../../icons/AnyIcon";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import PeopleIcon from "../../../../icons/PeopleIcon";
import {useSelectorEx} from "../../../../../hooks/redux";
import FunctionStripEffect from "../../../../../hooks/useFunctionStrip";
gsap.registerPlugin(ScrollTrigger);
const MainSection = () => {

    const sectionRef = useRef<HTMLDivElement>(null);
    const formattedEffect1 = FunctionStripEffect('.main-section__header-text', 0.05);
    const formattedEffect2 = FunctionStripEffect('.main-section__subtitle-text', 0.05);
    const formattedEffect3 = FunctionStripEffect('.main-section__tagline', 0.05);
    const formattedEffect4 = FunctionStripEffect('.main-section__desc', 0.02);


    const {preloader,preloaderStatus} = useSelectorEx(state => state.app)

    useEffect(()=>{
        if(!preloader && preloaderStatus >= 100){

            formattedEffect1();
            formattedEffect2();
            formattedEffect3();
            formattedEffect4();

            const timeline = gsap.timeline({})
            timeline.fromTo(".main-section__multi-line span",{
                    visibility:'hidden',
                    x: 0,
                    y: '10vw',
                    duration: 1,
                    delay: 0.1,
                    stagger: 0.1, // Задержка между появлением каждой буквы
                },
                {
                    visibility:'visible',
                    x: 0,
                    y: '0vw',
                    duration: 1,
                    delay: 0.1,
                    stagger: 0.1, // Задержка между появлением каждой буквы
                },
                1.2
            )

            timeline.fromTo(".header",{
                    opacity:0,
                    y: '-50%',
                    duration: 1
                },
                {
                    opacity:1,
                    y: '0%',
                    duration: 1
                }
                , 3.2
            )
        }

    },[preloader,preloaderStatus])//data-scroll-section
    return (
        <section ref={sectionRef} className={'main-section'}  id="main">
            <div className="container">
                <div className="main-section__background">
                    <CalligraphyIcon/>
                </div>
                <div className="main-section__wrapper">
                    <div className="main-section__header">
                        <h1 className="h-type main-section__header-line">
                            {(!preloader && preloaderStatus >= 100) && <div data-scroll={true} data-scroll-speed={2.5}  className="main-section__title">
                               <AnyIcon  />
                                <MultiLine  className={'main-section__multi-line'}/>
                               <div  className={'main-section__header-text strip'}>
                                    business
                                </div>
                            </div>}
                            {(!preloader && preloaderStatus >= 100) &&<div data-scroll={true} data-scroll-speed={1.5} className="main-section__subtitle">
                                <div className={'main-section__subtitle-text strip'}>{/*ref={stripRef2}*/}
                                    is all about
                                </div>
                                <PeopleIcon/>

                            </div>}
                        </h1>
                    </div>
                    <div className="main-section__content">

                        {(!preloader && preloaderStatus >= 100) && <div  data-scroll={true} data-scroll-speed={-1} className="main-section__tagline strip">{/*ref={stripRef3} */}
                                we are your people
                            </div>}


                        {(!preloader && preloaderStatus >= 100) && <div  data-scroll={true} data-scroll-speed={-0.4} className="main-section__desc strip">{/*ref={stripRef4}*/}
                            Performance marketing <br/>agency. We sell goods <br/>and services through <br/>online advertising
                        </div>}
                    </div>
                    <div className="main-section__line"></div>
                </div>
            </div>
        </section>
    );
};

export default MainSection;