import React from 'react';

const BagIcon = () => {
    return (
        <svg width="76" height="75" viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_155_48)">
                <path d="M64.4257 28.8867H12.4409C7.19773 28.8867 2.94727 33.1372 2.94727 38.3804L2.94727 62.2528C2.94727 67.496 7.19773 71.7465 12.4409 71.7465H64.4257C69.6689 71.7465 73.9194 67.496 73.9194 62.2528V38.3804C73.9194 33.1372 69.6689 28.8867 64.4257 28.8867Z" stroke="white" strokeWidth="2.8481"/>
                <path d="M2.71533 54.9258C7.55434 55.3866 17.6472 59.4422 19.3062 71.9776" stroke="white" strokeWidth="3.79747" strokeLinejoin="round"/>
                <path d="M73.9195 54.9258C69.0804 55.3866 58.9876 59.4422 57.3286 71.9776" stroke="white" strokeWidth="3.79747" strokeLinejoin="round"/>
                <path d="M23.2245 35.3381C23.2245 30.669 23.2245 23.8089 23.2245 17.5942C23.2245 9.4494 29.8272 2.84766 37.972 2.84766C46.1168 2.84766 52.7195 9.45032 52.7195 17.5951V35.3381M8.70752 43.864H15.1596M15.1596 43.864H68.619M15.1596 43.864V50.5464" stroke="white" strokeWidth="2.8481" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_155_48">
                    <rect width="75" height="73.1013" fill="white" transform="translate(0.816406 0.949707)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default BagIcon;