import React from 'react';

const MedicineIcon = () => {
    return (
        <svg width="76" height="80" viewBox="0 0 76 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.4684 5.17822V25.5476H3.26343L3.26343 53.4477H23.4684V74.8211H50.91V53.4477H72.9063V25.5476H50.91V5.17822H23.4684Z" stroke="white" strokeWidth="4.01786" strokeLinejoin="round"/>
        </svg>
    );
};

export default MedicineIcon;