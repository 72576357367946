import React from 'react';

const LeadIcon = () => {
    return (
        <svg width="76" height="80" viewBox="0 0 76 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_155_22)">
                <path d="M30.6812 59.0476V77.1008C30.6812 77.7913 31.3932 78.2523 32.0232 77.9699L43.928 72.6332C44.2704 72.4797 44.4908 72.1394 44.4908 71.7642V59.3473C44.4908 59.1523 44.5507 58.9618 44.6624 58.8019L67.7301 25.7835C68.1712 25.1522 67.7194 24.2857 66.9493 24.2857H10.9586C10.2132 24.2857 9.75668 25.1033 10.1478 25.7378L15.1235 33.8095M30.6812 59.0476L15.1235 33.8095M30.6812 59.0476H39.2527M15.1235 33.8095H46.8717M8.30029 0V12.8571M29.2527 1.90476V11.9048M59.7289 8.57143V18.5714" stroke="white" strokeWidth="2.85714"/>
                <path d="M23.0621 23.3341C25.429 23.3341 27.3478 21.4153 27.3478 19.0484C27.3478 16.6815 25.429 14.7627 23.0621 14.7627C20.6951 14.7627 18.7764 16.6815 18.7764 19.0484C18.7764 21.4153 20.6951 23.3341 23.0621 23.3341Z" stroke="white" strokeWidth="2.85714"/>
                <path d="M43.0616 10.9528C45.4285 10.9528 47.3473 9.034 47.3473 6.66706C47.3473 4.30013 45.4285 2.38135 43.0616 2.38135C40.6947 2.38135 38.7759 4.30013 38.7759 6.66706C38.7759 9.034 40.6947 10.9528 43.0616 10.9528Z" stroke="white" strokeWidth="2.85714"/>
                <path d="M55.4437 25.7148C55.4437 21.5068 52.0325 18.0957 47.8246 18.0957C43.6167 18.0957 40.2056 21.5068 40.2056 25.7148" stroke="white" strokeWidth="2.85714"/>
            </g>
            <defs>
                <clipPath id="clip0_155_22">
                    <rect width="63.8095" height="80" fill="white" transform="translate(6.39551)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default LeadIcon;