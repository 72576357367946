import React from 'react';

const ContextIcon = () => {
    return (
        <svg width="75" height="76" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_0_660" maskUnits="userSpaceOnUse" x="0" y="0" width="75" height="76">
                <path d="M75 0.855957H0V75.856H75V0.855957Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_0_660)">
                <path d="M28.116 12.6299C28.4849 11.9935 28.9196 11.399 29.4118 10.8564C32.8507 15.8106 38.1887 23.4736 42.2252 29.2683L42.5045 29.6691L22.6601 63.8909C22.6597 63.8916 22.6594 63.8923 22.659 63.8929C21.2561 66.2948 18.9599 68.0443 16.2716 68.7593C13.584 69.4741 10.7229 69.0969 8.31246 67.7101C5.91128 66.3071 4.16242 64.0112 3.4476 61.3236C2.73258 58.6351 3.11028 55.773 4.49818 53.3621C4.49845 53.3616 4.49874 53.3611 4.49902 53.3606L28.116 12.6299Z" fill="#FB6020" stroke="white" strokeWidth="2.8481"/>
                <path d="M24.0743 58.6296C24.0743 64.4268 19.3746 69.1264 13.5774 69.1264C7.78016 69.1264 3.08057 64.4268 3.08057 58.6296C3.08057 52.8322 7.78016 48.1327 13.5774 48.1327C19.3746 48.1327 24.0743 52.8322 24.0743 58.6296Z" fill="#FB6020" stroke="white" strokeWidth="2.8481"/>
                <path d="M65.9704 67.7651C60.9498 70.6637 54.5301 68.9436 51.6315 63.923L28.0877 23.1441C25.1891 18.1236 26.9093 11.7038 31.9298 8.80516C36.9505 5.90653 43.3702 7.62671 46.2688 12.6472L69.8125 53.4262C72.7111 58.4467 70.991 64.8665 65.9704 67.7651Z" fill="#FB6020" stroke="white" strokeWidth="2.8481"/>
            </g>
        </svg>
    );
};

export default ContextIcon;