import React, {useEffect, useLayoutEffect, useRef} from 'react';

import people from '../../../../../assets/images/people.png';

/*import gsap from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";*/
import useFormattedStripEffect from '../../../../../hooks/useFormattedStrip';
import useLuddyAnimation from "./useAnimationLuddy";
//import useLuddyAnimation from "./useAnimationLuddy";
//gsap.registerPlugin(ScrollTrigger);
const LuddySection = () => {

   // const elementRef = useFadeInEffect(0.5);
   const stripRef = useFormattedStripEffect(0.02);

    const {
            refTarget,
            refTarget2,
            refTarget3,
            refTarget4,
            refHeaderTarget,
    } = useLuddyAnimation();

     /*   { refTarget:null,
            refTarget2:null,
            refTarget3:null,
            refTarget4:null,
            refHeaderTarget:null,
        }*/



    return (
        <section ref={refTarget} className={'luddy-section'}  id="about">
            <div className="container">
                <div className="luddy-section__wrapper">
                    <div ref={refHeaderTarget} data-scroll data-scroll-speed={-1.0} className="luddy-section__header hidden h1"><span>lyudy</span>.Digital</div>
                    <div ref={refTarget2} className="luddy-section__inner">
                        <div ref={stripRef}  className="luddy-section__description strip">We buy almost all high-quality specialized courses on the market to develop our skills</div>
                        <div  className="luddy-section__image-carousel">
                            <div className={'luddy-section__image'} ref={refTarget3}>
                                <img src={people} alt=""/>
                            {/*    <img src={people} alt=""/>*/}
                            </div>
                            <div className="luddy-section__image-carousel-mask">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default LuddySection;