import React, { useEffect } from 'react';
import useMarqueeAnimation from "./useMarqueeAnimation";

const MarqueeSection = () => {
    const {refTarget,ref1,ref2} = useMarqueeAnimation();
    return (
        <section ref={refTarget}  className={'marquee-section'} id="marquee">
            <div className="marquee-section__wrapper">
                <div  className="marquee-section__white-line">
                    <ul ref={ref1}  >
                        <li>We audit your website and social networks</li>
                        <li>We audit your website and social networks</li>
                        <li>We audit your website and social networks</li>
                        <li>We audit your website and social networks</li>
                    </ul>
                </div>
                <div  className="marquee-section__orange-line">
                    <ul ref={ref2} >
                        <li>Why did they choose us?</li>
                        <li>Why did they choose us?</li>
                        <li>Why did they choose us?</li>
                        <li>Why did they choose us?</li>
                    </ul>

                </div>
            </div>
        </section>
    );
};

export default MarqueeSection;