import { useLayoutEffect, useRef, useState,RefObject} from 'react';
import gsap from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import { wrapLettersInElements} from "../helpers/scripts";

const useFormattedStripEffect = (time=0.1): RefObject<HTMLDivElement> => {
    gsap.registerPlugin(ScrollTrigger);
    const stripRef = useRef<HTMLDivElement>(null);
    const [initialText,setInitialText] = useState<string>('');

    useLayoutEffect(() => {
        const stripElement = stripRef.current;
        if (!stripElement) return;

        setInitialText(stripElement.innerHTML)

        stripRef.current.innerHTML = wrapLettersInElements(stripRef.current.innerHTML);
        const timeline = gsap.timeline();
        setTimeout(() => {
            const stripSpans = stripRef.current!.querySelectorAll('.line-row div');
            gsap.fromTo(stripSpans, {
                opacity: 0,
                x:0,
                y: '100%',
            }, {
                opacity: 1,
                y: '0%',
                x:0,
                duration: 1,
                delay: 0.1,
                stagger: time,
                scrollTrigger: {
                    trigger: stripRef.current,
                    scroller: ".scroll-container",
                    start: 'top 80%',
                    end: 'bottom 20%',
                },
            });
          //  ScrollTrigger.refresh();
        },1000);
      //  ScrollTrigger.refresh();
        return () => {
            timeline.kill();
         //   ScrollTrigger.getAll().forEach(st => st.kill())
            // Восстановление изначального текста
            stripElement.innerHTML = initialText;
            setInitialText('');
        };
    }, [stripRef]);

    return stripRef;
};

export default useFormattedStripEffect;