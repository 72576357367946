import React, {FC, useState} from 'react';
//import Eye from "../icons/Eye";
interface IInput {
    className?: string;
    classError?: string;
    name: string;
    type?: "text" | "password" | "email";
    placeholder: string;
    message?: string | undefined | false | any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    value?: string;
    innerRef?: any;
}
const Input:FC<IInput> = ({innerRef,name,className="",classError,type="text",placeholder,onBlur,onChange,value,message}) => {
    return (
        <div className={`input ${className}`}>
            <input ref={innerRef} placeholder={placeholder} type={!type ? 'text' : type} onChange={onChange} onBlur={onBlur} name={name} value={value} />
            {message && <p style={{color:"#DB3522"}}>{message}</p>}
        </div>
    );
};

export default Input;