import React, { useState } from 'react';
import logo from '../../../../../assets/images/logo.svg';
import Button from "../../../atoms/Button";
import BurgerIcon from "../../../../icons/BurgerIcon";
import {useDispatchEx, useSelectorEx} from "../../../../../hooks/redux";
import {changeModalMenu} from "../../../../../redux/slices/appSlice";
import {useLocomotiveScroll} from "react-locomotive-scroll";
const Header = () => {
    const { scroll } = useLocomotiveScroll();
    const dispatch = useDispatchEx();
    const [burger, serBurger]= useState<boolean>(false);
    const onOpen = () =>{
        dispatch(changeModalMenu(!burger));
        serBurger(!burger)
    }
    const {modalMenu,activeHeader} = useSelectorEx(state => state.app)
    return (
        <header className={`header hidden ${activeHeader ? "active" : ""} ${modalMenu ? "active-menu": ""}`} id="header">
            <div className="container">
                <div className="header__wrapper">
                    <div className="header__logo">
                        <img src={logo}  alt={'logo'}/>
                    </div>
                    <div className="header__menu-about">
                        <ul className={'menu'}>
                            <li className={'menu__item'}><a onClick={()=>scroll?.scrollTo('#about')}>About ЛЮДИ.digital</a></li>
                            <li className={'menu__item'}><a onClick={()=>scroll?.scrollTo('#cases')}>Cases</a></li>
                            <li className={'menu__item'}><a onClick={()=>scroll?.scrollTo('#about')}>Team</a></li>
                        </ul>
                    </div>
                    <div className="header__menu">
                        <ul className={'menu'}>
                            <li className={'menu__item'}><a onClick={()=>scroll?.scrollTo('#stages')}>Work stages</a></li>
                            <li className={'menu__item'}><a onClick={()=>scroll?.scrollTo('#contact')}>Consultation</a></li>
                        </ul>
                        <div className={`header__feedback ${modalMenu ? "active-menu": ""}`}>
                            <Button onClick={()=>scroll?.scrollTo('#contact')} className={'button-orange'} name={'Contact us'} effect={'bubble'}/>
                        </div>
                        <div className="header__burger">
                            <div className="header__burger-wrapper">
                                <BurgerIcon active={burger} onOpen={onOpen}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </header>
    );
};

export default Header;