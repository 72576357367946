import {useRef, useLayoutEffect, useCallback} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import useResizeObserver from "../../../../../hooks/useResizeObserver";
import {useSelectorEx} from "../../../../../hooks/redux";

gsap.registerPlugin(ScrollTrigger);

const useExperienceAnimation = () => {

    const refTarget = useRef(null);
    const refTarget1 = useRef(null);
    const refTarget2 = useRef(null);
    const refItemsTarget = useRef(null);

    const animationLarge = (timeline:any) => {
        gsap.set(refItemsTarget.current,{
            opacity:0,
        })
        timeline.to(refItemsTarget.current, {
            scrollTrigger: {
                id: "useExperienceAnimation1",
                trigger: refTarget.current,
                scroller: ".scroll-container",
                start: "top-=70% top",
                end: "100% bottom",
                onUpdate: (self: any) => {
                    gsap.to(refItemsTarget.current, { opacity: self.progress });
                },
            },
            ease: "none",
        });
        timeline.to(refTarget.current, {
            scrollTrigger: {
                id: "useExperienceAnimation2",
                trigger: refTarget.current,
                scroller: ".scroll-container",
                //scrub: 10,
                pin: false,
                start: "top top",
                end: "150% bottom",
                onUpdate: (self: any) => {
                    //self.progress
                    gsap.to(refTarget1.current, { x: `-${100*self.progress}%` });
                    gsap.to(refTarget2.current, { x: `-${100-100*self.progress}%` });
                },
            },
            ease: "none",
        });

    };

    const animationMob = (timeline:any) => {
        gsap.set(refItemsTarget.current,{
            opacity:0,
        })
        timeline.to(refItemsTarget.current, {
            scrollTrigger: {
                id: "useExperienceAnimation1",
                trigger: refTarget.current,
                scroller: ".scroll-container",
                start: "top-=30% top",
                end: "100% bottom",
                onUpdate: (self: any) => {
                    gsap.to(refItemsTarget.current, { opacity: self.progress });
                },
            },
            ease: "none",
        });
        timeline.to(refTarget.current, {
            scrollTrigger: {
                id: "useExperienceAnimation2",
                trigger: refTarget.current,
                scroller: ".scroll-container",
                scrub: 10,
                pin: false,
                start: "top top",
                end: "200% bottom",
                onUpdate: (self: any) => {
                    //self.progress
                    gsap.to(refTarget1.current, { x: `-${400-400*self.progress}%` });
                    gsap.to(refTarget2.current, { x: `-${400*self.progress}%` });
                },
            },
            ease: "none",
        });
    };

    const {preloader,preloaderStatus} = useSelectorEx(state => state.app)


    useLayoutEffect(() => {
        if(!preloader && preloaderStatus >= 100) {
            const timeline = gsap.timeline();
            let screenWidth:any = window.innerWidth;

            const animate = () => {
                if (screenWidth >= 768) {
                    animationLarge(timeline);
                } else {
                    animationMob(timeline);
                }
            };

            animate();

            const handleResize = () => {
                if(ScrollTrigger.getAll().length >= 1) {
                    ScrollTrigger.getById("useExperienceAnimation1")?.kill(true);
                    ScrollTrigger.getById("useExperienceAnimation2")?.kill(true);
                }
                timeline.kill();
                timeline.clear();
                animate();
            };

            window.addEventListener('resize', handleResize);

            return () => {
                timeline.kill();
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [preloader,preloaderStatus]);

    return {
        refTarget,
        refTarget1,
        refTarget2,
        refItemsTarget
    };
};

export default useExperienceAnimation;


