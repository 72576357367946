import React, {useLayoutEffect, useRef} from 'react';
import ArrowIcon from '../../../../icons/ArrowIcon';
import RevealImage from '../../../../RevealImage';

import sovaImg from '../../../../../assets/images/cases/1.png';
import duckImg from '../../../../../assets/images/cases/2.png';
import geliusImg from '../../../../../assets/images/cases/3.png';

import gsap from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import useFormattedStripEffect from "../../../../../hooks/useFormattedStrip";
import useCasesAnimation from "./useCasesAnimation";

gsap.registerPlugin(ScrollTrigger);

const CasesSection = () => {
    const {refTarget} = useCasesAnimation();
    const stripRef2 = useFormattedStripEffect(0.02);

    return (
        <section ref={refTarget} className={'cases-section'}  id="cases">
            <div className="container">
                <div className="cases-section__wrapper">
                    <div className="cases-section__header">
                        <h2 ref={stripRef2} className="h1 cases-section__h2 strip"><span>Our</span><br/> cases</h2>
                    </div>
                    <div className="cases-section__items">
                        <div className="cases-section__item">
                            <RevealImage imageUrl={sovaImg}>
                            <div className="cases-section__item-title">Sova</div>
                            <div className="cases-section__item-description">2 years of continuous cooperation Branded and conversion advertising campaigns, online sales of high value goods. </div>
                            <div className="cases-section__item-link">
                                <a href="src/components/sections#">
                                    <ArrowIcon/>
                                </a>
                            </div>
                            </RevealImage>
                        </div>
                        <div className="cases-section__item">
                            <RevealImage imageUrl={duckImg}>
                            <div className="cases-section__item-title">Duck, Please</div>
                            <div className="cases-section__item-description">A year of continuous cooperation. We have generated dozens of creative ads for advertising campaigns of restaurant and delivery campaigns. </div>
                            <div className="cases-section__item-link">
                                <a href="src/components/sections#">
                                    <ArrowIcon/>
                                </a>
                            </div>
                        </RevealImage>
                        </div>
                        <div className="cases-section__item">
                            <RevealImage imageUrl={geliusImg}>
                            <div className="cases-section__item-title">Gelius</div>
                            <div className="cases-section__item-description">We selected relevant products for sale out of season. Prepared creative ads. Set up repeated communication with users to close the sale. </div>
                            <div className="cases-section__item-link">
                                <a href="src/components/sections#">
                                    <ArrowIcon/>
                                </a>
                            </div>
                            </RevealImage>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CasesSection;