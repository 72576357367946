import React from 'react';

const LaptopIcon = () => {
    return (
        <svg width="135" height="66" viewBox="0 0 135 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M94.0302 3.05737H9.53076V56.8297H94.0302V3.05737Z" stroke="white" strokeWidth="2.77397" strokeLinejoin="round"/>
            <path d="M101.712 63.5514H1.84912V56.8298H101.712V63.5514Z" stroke="white" strokeWidth="2.77397" strokeLinejoin="round"/>
            <path d="M133.151 23.7911H110.959V63.5514H133.151V23.7911Z" stroke="white" strokeWidth="2.77397" strokeLinejoin="round"/>
            <path d="M122.517 58.928C123.283 58.928 123.904 58.3071 123.904 57.541C123.904 56.775 123.283 56.1541 122.517 56.1541C121.751 56.1541 121.13 56.775 121.13 57.541C121.13 58.3071 121.751 58.928 122.517 58.928Z" fill="white"/>
        </svg>
    );
};

export default LaptopIcon;