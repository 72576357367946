import React, {FC} from 'react';

interface IBurgerIcon{
    active: boolean;
    onOpen?: ()=>void;
}
const BurgerIcon:FC<IBurgerIcon> = ({active=false,onOpen=()=>{}}) => {
    return (
        <svg onClick={onOpen} className={`burger ${active ? "active": ""}`} viewBox="20 23 60 60" width="80" >
            <path className="burger__line burger__top" d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"/>
            <path className="burger__line burger__middle" d="m 70,50 h -40"/>
            <path className="burger__line burger__bottom" d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"/>
        </svg>
    );
};

export default BurgerIcon;