import { useState, useEffect } from "react";
import gsap from "gsap";
import {useDispatchEx} from "./redux";
import {changePreloader, changePreloaderStatus} from "../redux/slices/appSlice";

const usePreloader = (images:any) => {
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatchEx();
    useEffect(() => {
        const timeline = gsap.timeline();
        function loadImage(imgSrc:string) {
            return new Promise((resolve, reject) => {
                const image = new Image();
                image.src = imgSrc;
                image.onload = resolve;
                image.onerror = reject;
            });
        }
        const totalImages = images.length;
        let loadedImages = 0;
        Promise.all(
            images.map((img:any) => {
                return loadImage(img).then(() => {
                    loadedImages++;
                    const progress = (loadedImages / totalImages) * 100;
                    dispatch(changePreloaderStatus(Math.floor(progress)));
                });
            })
        ).then(() => {
                setTimeout(() => {
                    const UpStart = {opacity:1, duration: 0.5, stagger: 0.5 };
                    const InFinish = {opacity:0, duration: 0.5, stagger: 0.5 };
                    timeline.fromTo(".preloader", UpStart, InFinish, 0.1);
                }, 2000);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                setTimeout(() => {
                    dispatch(changePreloader(false));
                    setIsLoading(false);
                }, 3000);
        });
    }, []);

    return isLoading;
};

export default usePreloader;