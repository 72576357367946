import React from 'react';

const TargetIcon = () => {
    return (
        <svg width="75" height="81" viewBox="0 0 75 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_0_642)">
                <path d="M37.4989 54.8809C45.446 54.8809 51.8884 48.4385 51.8884 40.4914C51.8884 32.5443 45.446 26.1019 37.4989 26.1019C29.5518 26.1019 23.1094 32.5443 23.1094 40.4914C23.1094 48.4385 29.5518 54.8809 37.4989 54.8809Z" stroke="white" strokeWidth="2.61627"/>
                <path d="M45.3477 40.0551H73.2546" stroke="white" strokeWidth="2.61627" strokeLinecap="round"/>
                <path d="M1.74414 40.0551H29.6511" stroke="white" strokeWidth="2.61627" strokeLinecap="round"/>
                <path d="M37.5 32.2063V4.29932" stroke="white" strokeWidth="2.61627" strokeLinecap="round"/>
                <path d="M37.5 75.8115V47.9045" stroke="white" strokeWidth="2.61627" strokeLinecap="round"/>
                <path d="M37.5012 67.5256C52.6729 67.5256 64.972 55.2265 64.972 40.0547C64.972 24.883 52.6729 12.5839 37.5012 12.5839C22.3294 12.5839 10.0303 24.883 10.0303 40.0547C10.0303 55.2265 22.3294 67.5256 37.5012 67.5256Z" stroke="white" strokeWidth="2.61627"/>
            </g>
            <defs>
                <clipPath id="clip0_0_642">
                    <rect width="74.9999" height="74.9999" fill="white" transform="translate(0 2.85583)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default TargetIcon;