import {useRef, useLayoutEffect, useCallback, useState, useEffect} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import useResizeObserver from "../../../../../hooks/useResizeObserver";
import {useSelectorEx} from "../../../../../hooks/redux";

gsap.registerPlugin(ScrollTrigger);

const useLuddyAnimation = () => {
    const refTarget = useRef<any>(null);
    const refTarget2 = useRef<any>(null);
    const refTarget3 = useRef<any>(null);
    const refTarget4 = useRef<any>(null);
    const refHeaderTarget = useRef<any>(null);

    const animationLuddyLarge = (timeline:any) => {

        timeline.to(refHeaderTarget.current, {
            scrollTrigger: {

                trigger: refTarget.current,
                scroller: ".scroll-container",
                start: "top top+40%",
                end: "40% bottom",
                onUpdate: (self: any) => {
                    gsap.to(refHeaderTarget.current, { opacity: self.progress });
                },
                onComplete: (self: any) => {
                    refHeaderTarget.current?.classList.remove("hidden");
                }
            },
            ease: "none",
        });
/*        gsap.timeline({
            scrollTrigger: {
                trigger: '.luddy-section__image-carousel',
                scroller: ".scroll-container",
            }
        }).fromTo('.luddy-section__image-carousel', {
            opacity:0,
            scale: 0.8
            },
            {
                opacity:1,
                scale: 1
            });*/
        timeline.to(refTarget3.current, {
            scrollTrigger: {
                id: "trigger123",
                trigger: refTarget3.current,
                scroller: ".scroll-container",
                start: "top 40%",
                end: "top top",

                onUpdate: (self: any) => {
                    console.log('large',self.progress)
                    gsap.to(refTarget3.current, { x: `${-self.progress * 27}%` });
                },
            },
            ease: "none",
        });
    };

    const animationLuddyMob = (timeline:any) => {

        timeline.to(refHeaderTarget.current, {
            scrollTrigger: {
                trigger: refTarget.current,
                scroller: ".scroll-container",
                start: "top+=20% top",
                end: "40% bottom",
                onUpdate: (self: any) => {

                    gsap.to(refHeaderTarget.current, { opacity: self.progress });
                },
            },
            ease: "none",
        });
        timeline.to(refTarget3.current, {
            scrollTrigger: {
                id: "trigger123",
                trigger: refTarget3.current,
                scroller: ".scroll-container",
                start: "top 40%",
                end: "top top",

                onUpdate: (self: any) => {
                    console.log('mob',self.progress)
                    gsap.to(refTarget3.current, { x: `${-self.progress * 250}%` });
                },
            },
            ease: "none",
        });
    };

    const {preloader,preloaderStatus} = useSelectorEx(state => state.app)

    useEffect(() => {
        if (!preloader && preloaderStatus >= 100) {
            const timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: refTarget.current,
                    scroller: ".scroll-container",
                }
            });

            console.log('timeline')
            const animateLuddy = () => {
                let screenWidth:any = window.innerWidth;
                if (screenWidth >= 768) {
                    animationLuddyLarge(timeline);
                } else {
                    animationLuddyMob(timeline);
                }
            };

            animateLuddy();

            const handleResize = () => {
                if(ScrollTrigger.getAll().length >= 1) {
                    ScrollTrigger.getById("trigger123")?.kill(true);
                }
                timeline.kill();
                timeline.clear();
                animateLuddy();
            };

            window.addEventListener('resize', handleResize);

            return () => {
                timeline.kill();
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [preloader, preloaderStatus]);

    return {
        refTarget,
        refTarget2,
        refTarget3,
        refTarget4,
        refHeaderTarget,
    };
};

export default useLuddyAnimation;


