import React, {useEffect, useLayoutEffect, useRef} from 'react';
import IconBlock from '../../../../other/IconBlock';
import CartIcon from "../../../../icons/listIcons/CartIcon";
import PhoneIcon from "../../../../icons/listIcons/PhoneIcon";
import LeadIcon from "../../../../icons/listIcons/LeadIcon";
import InfoIcon from "../../../../icons/listIcons/InfoIcon";
import BagIcon from "../../../../icons/listIcons/BagIcon";
import TshirtIcon from "../../../../icons/listIcons/TshirtIcon";
import CelebrateIcon from "../../../../icons/listIcons/CelebrateIcon";
import DeliveryIcon from "../../../../icons/listIcons/DeliveryIcon";
import BrilliantIcon from "../../../../icons/listIcons/BrilliantIcon";
import MedicineIcon from "../../../../icons/listIcons/MedicineIcon";
import FranchiseIcon from "../../../../icons/listIcons/FranchiseIcon";
import useFormattedStripEffect from "../../../../../hooks/useFormattedStrip";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import useExperienceAnimation from "./useExperienceAnimation";
//import useResizeObserver from "../../hooks/useResizeObserver";

gsap.registerPlugin(ScrollTrigger);
const ExperienceSection = () => {

    const stripRef1 = useFormattedStripEffect(0.02);
    const stripRef2 = useFormattedStripEffect(0.02);

    const {
        refTarget,
        refTarget1,
        refTarget2,
        refItemsTarget,
    } = useExperienceAnimation();

    return (
        <section  className={'experience-section'}  id="experience">
            <div className="container">
                <div ref={refTarget} className="experience-section__wrapper">
                    <div className="experience-section__header">
                        <div className={'experience-section__col'}>
                            <h2 ref={stripRef1} className={'h2 experience-section__h2 strip'}>
                                Areas, in which<br/>
                                <span>we have a lot<br/></span>
                                of experience
                            </h2>
                        </div>
                        <div className={'experience-section__col'}>
                            <div ref={stripRef2} className="experience-section__description strip">
                                In 4 years, we have launched the program<br/>
                                in more than 300 projects
                            </div>
                        </div>
                    </div>
                    <div ref={refItemsTarget} className="experience-section__inner">
                        <div ref={refTarget1} className="experience-section__items first" >
                            <div className="experience-section__item">
                                <IconBlock title={'E-commerce'}><CartIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Mobile App'}><PhoneIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Lead Generation'}><LeadIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Infobusiness'}><InfoIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Clothes'}><TshirtIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Product'}><BagIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Events'}><CelebrateIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Food delivery'}><DeliveryIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Jewelry'}><BrilliantIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Medicine'}><MedicineIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Franchise'}><FranchiseIcon/></IconBlock>
                            </div>
                        </div>
                        <div ref={refTarget2} className="experience-section__items second">
                            <div className="experience-section__item">
                                <IconBlock title={'E-commerce'}><CartIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Mobile App'}><PhoneIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Lead Generation'}><LeadIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Infobusiness'}><InfoIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Clothes'}><TshirtIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Product'}><BagIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Events'}><CelebrateIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Food delivery'}><DeliveryIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Jewelry'}><BrilliantIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Medicine'}><MedicineIcon/></IconBlock>
                            </div>
                            <div className="experience-section__item">
                                <IconBlock title={'Franchise'}><FranchiseIcon/></IconBlock>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ExperienceSection;