import React from 'react';

const TshirtIcon = () => {
    return (
        <svg width="76" height="75" viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_155_36)">
                <mask id="mask0_155_36" maskUnits="userSpaceOnUse" x="0" y="0" width="76" height="75">
                    <path d="M75.479 0L0.479004 0L0.479004 75H75.479V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_155_36)">
                    <path d="M56.7285 35.1562H64.668C65.1005 35.1572 65.5246 35.0369 65.8923 34.8091C66.2599 34.5812 66.5564 34.2549 66.748 33.8672L72.1387 23.0859C72.4057 22.5459 72.4575 21.9247 72.2834 21.3479C72.1093 20.7713 71.7224 20.2824 71.2012 19.9805L56.7285 11.7188" stroke="white" strokeWidth="2.47253" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M19.2279 35.1562H11.2884C10.8559 35.1572 10.4318 35.0369 10.0641 34.8091C9.69647 34.5812 9.40001 34.2549 9.20839 33.8672L3.81776 23.0859C3.55069 22.5459 3.49895 21.9247 3.67305 21.3479C3.84714 20.7713 4.23402 20.2824 4.75526 19.9805L19.2279 11.7188" stroke="white" strokeWidth="2.47253" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M47.3525 11.7188C47.3525 14.2051 46.3648 16.5897 44.6066 18.3478C42.8485 20.106 40.4639 21.0937 37.9775 21.0938C35.4912 21.0937 33.1066 20.106 31.3484 18.3478C29.5902 16.5897 28.6025 14.2051 28.6025 11.7188H19.2275V60.9375C19.2275 61.5591 19.4745 62.1552 19.914 62.5947C20.3535 63.0343 20.9497 63.2812 21.5713 63.2812H54.3837C55.0054 63.2812 55.6016 63.0343 56.0411 62.5947C56.4806 62.1552 56.7275 61.5591 56.7275 60.9375V11.7188H47.3525Z" stroke="white" strokeWidth="2.47253" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_155_36">
                    <rect width="75" height="75" fill="white" transform="translate(0.477539)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default TshirtIcon;