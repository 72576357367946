import React from 'react';

const PhoneIcon = () => {
    return (
        <svg width="76" height="80" viewBox="0 0 76 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M52.0248 8.92383H21.1733C17.2141 8.92383 13.9272 12.136 13.9272 16.0951V65.5474C13.9272 69.5065 17.1394 72.7186 21.1733 72.7186H52.0248C55.9841 72.7186 59.2709 69.5065 59.2709 65.5474V16.0951C59.2709 12.136 56.0588 8.92383 52.0248 8.92383ZM57.0299 65.5474C57.0299 68.3113 54.7888 70.4776 52.0248 70.4776H21.1733C18.4093 70.4776 16.1683 68.2366 16.1683 65.5474V16.0951C16.1683 13.3312 18.4093 11.1649 21.1733 11.1649H52.0248C54.7888 11.1649 57.0299 13.4059 57.0299 16.0951V65.5474Z" fill="white" stroke="white" strokeWidth="1.05634"/>
            <path d="M45.7485 16.0215H27.2972C26.6996 16.0215 26.1768 16.5444 26.1768 17.142C26.1768 17.7396 26.6996 18.2625 27.2972 18.2625H45.7485C46.3461 18.2625 46.8689 17.7396 46.8689 17.142C46.8689 16.5444 46.3461 16.0215 45.7485 16.0215Z" fill="white" stroke="white" strokeWidth="1.05634"/>
            <path d="M36.5603 58.8242C34.4686 58.8242 32.8252 60.5424 32.8252 62.5593C32.8252 63.5304 33.1987 64.5015 33.9458 65.1739C34.618 65.8461 35.5891 66.2196 36.5603 66.2196C38.6518 66.2196 40.2953 64.5015 40.2953 62.4845C40.2953 60.4677 38.6518 58.8242 36.5603 58.8242ZM36.635 64.0533C36.2615 64.0533 35.8879 63.9039 35.5891 63.6051C35.2904 63.3063 35.1409 62.9327 35.1409 62.5593C35.1409 61.7376 35.8133 61.0652 36.635 61.0652C37.4567 61.0652 38.129 61.7376 38.129 62.5593C38.129 63.3809 37.4567 64.0533 36.635 64.0533Z" fill="white" stroke="white" strokeWidth="1.05634"/>
        </svg>
    );
};

export default PhoneIcon;