import {useRef, useLayoutEffect, useCallback} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import useResizeObserver from "../../../../../hooks/useResizeObserver";
import {useSelectorEx} from "../../../../../hooks/redux";

gsap.registerPlugin(ScrollTrigger);

const useFormAnimation = () => {

    const refTarget = useRef(null);
    const refTarget1 = useRef(null);

    const animationLarge = (timeline:any) => {
        gsap.timeline({
        }).to(refTarget.current, {
            scrollTrigger: {
                id: "useFormAnimation2",
                trigger: refTarget.current,
                scroller: ".scroll-container",
                pin: false,
                start: "top-=10% top",
                end: "bottom top+=100%",
                onUpdate: (self: any) => {
                    gsap.to(refTarget1.current, { backgroundSize: `100% ${100*self.progress}%` });
                },
            },
            ease: "none",
        });
        gsap.timeline({
            scrollTrigger:{
                id: "useFormAnimation1",
                trigger: refTarget.current,
                scroller: ".scroll-container",
            }
        }).fromTo('.form-section__form',{
                    opacity:0,
                    duration: 1,
                    delay: 0.1,
                },
                {
                    opacity:1,
                    duration: 1,
                    delay: 0.1,
                },
            )
            .to('.form-section__input .input',{
                    className: 'input withLine'
                },
            );
    };

    const animationMob = (timeline:any) => {
        gsap.timeline({
        }).to(refTarget.current, {
            scrollTrigger: {
                id: "useFormAnimation2",
                trigger: refTarget.current,
                scroller: ".scroll-container",
                pin: false,
                start: "top-=70% top",
                end: "bottom top+=100%",
                onUpdate: (self: any) => {
                    gsap.to(refTarget1.current, { backgroundSize: `100% ${100*self.progress}%` });
                },
            },
            ease: "none",
        });
        gsap.timeline({
            scrollTrigger:{
                id: "useFormAnimation1",
                trigger: refTarget.current,
                scroller: ".scroll-container",
            }
        }).fromTo('.form-section__form',{
                opacity:0,
                duration: 1,
                delay: 0.1,
            },
            {
                opacity:1,
                duration: 1,
                delay: 0.1,
            },
        )
            .to('.form-section__input .input',{
                    className: 'input withLine'
                },
            );
    };

    const {preloader,preloaderStatus} = useSelectorEx(state => state.app)

    useLayoutEffect(() => {
        if(!preloader && preloaderStatus >= 100) {
            const timeline = gsap.timeline({
                scrollTrigger:{
                    id: "useFormAnimation1",
                    trigger: refTarget.current,
                    scroller: ".scroll-container",
                }
            });
            let screenWidth:any = window.innerWidth;

            const animate = () => {
                if (screenWidth >= 768) {
                    animationLarge(timeline);
                } else {
                    animationMob(timeline);
                }
            };

            animate();

            const handleResize = () => {
                if(ScrollTrigger.getAll().length >= 1) {
                    ScrollTrigger.getById("useFormAnimation1")?.kill(true);
                    ScrollTrigger.getById("useFormAnimation2")?.kill(true);
                }
                timeline.kill();
                timeline.clear();
                animate();
            };

            window.addEventListener('resize', handleResize);

            return () => {
                timeline.kill();
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [preloader,preloaderStatus]);

    return {
        refTarget,
        refTarget1
    };
};

export default useFormAnimation;


