import {useRef, useLayoutEffect, useCallback} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useSelectorEx} from "../../../../../hooks/redux";

gsap.registerPlugin(ScrollTrigger);

const usePeopleAnimation = () => {

    const refTarget = useRef(null);
    const refTarget1 = useRef(null);
    const refTarget2 = useRef(null);

    const animationLarge = (timeline:any, height:number=601) => {
        timeline.to(refTarget.current, {
            scrollTrigger: {
                id: "usePeopleAnimation1",
                trigger: refTarget.current,
                scroller: ".scroll-container",
              //  scrub: 10,
                pin: false,
                start: "top bottom",
                end: "150% bottom",
                onUpdate: (self: any) => {
                    console.log("peopleUpdate",self)
                    gsap.to(refTarget1.current, { transform: `translateX(-${100-100*self.progress}vw)` });
                    gsap.to(refTarget2.current, { transform: `translateX(-${100*self.progress*2}vw)` });
                },
            },
            ease: "none",
        });

    };

    const animationMob = (timeline:any) => {
        timeline.to(refTarget.current, {
            scrollTrigger: {
                id: "usePeopleAnimation1",
                trigger: refTarget.current,
                scroller: ".scroll-container",
                scrub: 10,
                pin: false,
                start: "top 50%",
                end: "400% bottom",
                onUpdate: (self: any) => {
                    gsap.to(refTarget1.current, { transform: `translateX(-${100-100*self.progress}vw)` });
                    gsap.to(refTarget2.current, { transform: `translateX(-${100*self.progress*2}vw)` });
                },
            },
            ease: "none",
        });
    };

    const {preloader,preloaderStatus} = useSelectorEx(state => state.app)

    useLayoutEffect(() => {
        if(!preloader && preloaderStatus >= 100) {
            const timeline = gsap.timeline({
                scrollTrigger:{
                    id: "usePeopleAnimation2",
                    trigger: refTarget.current,
                    scroller: ".scroll-container",
                }
            });


            const animate = () => {
                let screenWidth:any = window.innerWidth;
                if (screenWidth >= 768) {
                    animationLarge(timeline,window.innerHeight);
                } else {
                    animationMob(timeline);
                }
            };

            animate();

            const handleResize = () => {
                if(ScrollTrigger.getAll().length >= 1) {
                    ScrollTrigger.getById("usePeopleAnimation1")?.kill(true);
                    ScrollTrigger.getById("usePeopleAnimation2")?.kill(true);
                }
                timeline.kill();
                timeline.clear();
                animate();
            };

            window.addEventListener('resize', handleResize);

            return () => {
                timeline.kill();
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [preloader,preloaderStatus]);

    return {
        refTarget,
        refTarget1,
        refTarget2
    };
};

export default usePeopleAnimation;


