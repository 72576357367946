import React from 'react';

const CelebrateIcon = () => {
    return (
        <svg width="76" height="80" viewBox="0 0 76 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2215 47.5004H14.154V47.6594L2.35205 75.8024L54.294 54.0194L24.1351 23.8604L14.2215 47.5004ZM32.154 60.0509L26.154 62.5664V30.1214L32.154 36.1214V60.0509ZM35.154 39.1214L41.154 45.1214V56.2769L35.154 58.7924V39.1214ZM23.154 63.8249L17.154 66.3404V48.2639L23.154 33.9554V63.8249ZM14.154 55.4174V67.5989L7.95605 70.1984L14.154 55.4174ZM44.154 55.0184V48.1214L49.014 52.9814L44.154 55.0184Z" fill="white"/>
            <path d="M41.1543 15.9995C37.8452 15.9995 35.1543 18.6905 35.1543 21.9995C35.1543 25.3085 37.8452 27.9995 41.1543 27.9995C44.4633 27.9995 47.1543 25.3085 47.1543 21.9995C47.1543 18.6905 44.4633 15.9995 41.1543 15.9995ZM41.1543 24.9995C39.4998 24.9995 38.1543 23.654 38.1543 21.9995C38.1543 20.3451 39.4998 18.9995 41.1543 18.9995C42.8087 18.9995 44.1543 20.3451 44.1543 21.9995C44.1543 23.654 42.8087 24.9995 41.1543 24.9995Z" fill="white"/>
            <path d="M68.1543 4.00049C64.8453 4.00049 62.1543 6.69149 62.1543 10.0005C62.1543 13.3095 64.8453 16.0005 68.1543 16.0005C71.4633 16.0005 74.1543 13.3095 74.1543 10.0005C74.1543 6.69149 71.4633 4.00049 68.1543 4.00049ZM68.1543 13.0005C66.4998 13.0005 65.1543 11.655 65.1543 10.0005C65.1543 8.34599 66.4998 7.00049 68.1543 7.00049C69.8088 7.00049 71.1543 8.34599 71.1543 10.0005C71.1543 11.655 69.8088 13.0005 68.1543 13.0005Z" fill="white"/>
            <path d="M60.6548 28C57.3458 28 54.6548 30.691 54.6548 34C54.6548 37.309 57.3458 40 60.6548 40C63.9638 40 66.6548 37.309 66.6548 34C66.6548 30.691 63.9638 28 60.6548 28ZM60.6548 37C59.0003 37 57.6548 35.6544 57.6548 34C57.6548 32.3455 59.0003 31 60.6548 31C62.3093 31 63.6548 32.3455 63.6548 34C63.6548 35.6544 62.3093 37 60.6548 37Z" fill="white"/>
            <path d="M54.3662 48.5104L56.4872 50.6314C60.6512 46.4674 67.4297 46.4674 71.5937 50.6314L73.7147 48.5104C68.3792 43.1749 59.7002 43.1749 54.3662 48.5104Z" fill="white"/>
            <path d="M28.7143 24.5609C32.8078 20.4674 32.8078 13.8059 28.7143 9.7124L26.5933 11.8334C29.5168 14.7584 29.5168 19.5164 26.5933 22.4399L28.7143 24.5609Z" fill="white"/>
            <path d="M54.2153 24.1972C59.7622 18.6487 59.7622 9.62321 54.2153 4.07471L52.0942 6.19571C56.4712 10.5742 56.4712 17.6977 52.0942 22.0762L54.2153 24.1972Z" fill="white"/>
            <path d="M45.6553 32.5005H42.6538V35.502H45.6553V32.5005Z" fill="white"/>
            <path d="M69.6563 20.5H66.6548V23.5015H69.6563V20.5Z" fill="white"/>
            <path d="M39.6572 7.00049H36.6558V10.002H39.6572V7.00049Z" fill="white"/>
        </svg>
    );
};

export default CelebrateIcon;