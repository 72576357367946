import React, {useEffect, useLayoutEffect, useRef} from 'react';
import Button from "../../../atoms/Button";
import Input from "../../../atoms/Input";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import useFormattedStripEffect from "../../../../../hooks/useFormattedStrip";
import useFormAnimation from "./useFormAnimation";
gsap.registerPlugin(ScrollTrigger);
const FormSection = () => {

    const stripRef1 = useFormattedStripEffect(0.02);
    const stripRef2 = useFormattedStripEffect(0.02);

    const {refTarget,refTarget1} = useFormAnimation();

    return (
        <section ref={refTarget} className={'form-section'}  id="form-section">
            <div className="container">
                <div className="form-section__wrapper">
                    <h2 data-scroll={true} data-scroll-speed={1.5} className="h1 form-section__h2">
                        Lyudy.Digital
                        <span ref={refTarget1}>Lyudy.Digital</span>
                    </h2>
                    <div className="form-section__inner">
                        <div ref={stripRef1} className="form-section__header strip">
                            Do you want to organize advertising?
                        </div>
                        <div ref={stripRef2} className="form-section__description strip">
                            Leave a request and<br/>
                            we will study your project
                        </div>
                    </div>
                    <div className="form-section__form">
                        <div className="form-section__input">
                            <Input name={'name'} placeholder={'Name'}/>
                        </div>
                        <div className="form-section__input">
                            <Input name={'phone'} placeholder={'Phone number'}/>
                        </div>
                        <div className="form-section__input">

                        </div>
                        <div className="form-section__input">
                            <Input name={'link'} placeholder={'Link to the project'}/>
                        </div>
                        <div className="form-section__input">
                            <Input name={'additional'} placeholder={'Additional information'}/>
                        </div>
                        <div className="form-section__button">
                            <Button effect={'bubble'} className={'button-big'} name={'Submit'}/>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default FormSection;