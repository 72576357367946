import {createSlice} from "@reduxjs/toolkit";

interface appState {
    modalFeedback:boolean;
    modalLogin:boolean;
    modalMenu:boolean;
    activeHeader:boolean;
    isLoading: boolean;
    preloader:boolean;
    preloaderStatus:number;
    error: string
}

const initialState: appState = {
    modalFeedback:false,
    modalLogin:false,
    modalMenu:false,
    activeHeader:false,
    preloader:true,
    preloaderStatus:0,
    isLoading: false,
    error: ''
};


const appSlice = createSlice({
    name:'appSlice',
    initialState,
    reducers:{
        changeModalFeedback(state,action){
            state.modalFeedback = action.payload;
        },
        changeModalLogin(state,action){
            state.modalLogin = action.payload;
        },
        changePreloader(state,action){
            state.preloader = action.payload;
        },
        changePreloaderStatus(state,action){
            state.preloaderStatus = action.payload;
        },
        changeModalMenu(state,action){
            state.modalMenu = action.payload;
        },
        changeActiveHeader(state,action){
            state.activeHeader = action.payload;
        },
    }
});

export const {changeModalFeedback,changeModalLogin,changePreloader,changePreloaderStatus,changeModalMenu,changeActiveHeader} = appSlice.actions;
export default appSlice.reducer;