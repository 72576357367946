import {useRef, useLayoutEffect, useCallback} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import useResizeObserver from "../../../../../hooks/useResizeObserver";
import {useSelectorEx} from "../../../../../hooks/redux";

gsap.registerPlugin(ScrollTrigger);

const useStagesAnimation = () => {

    const refTarget = useRef(null);
    const refTarget1 = useRef(null);

    const animationLarge = (timeline:any) => {
        timeline.to(refTarget.current, {
            scrollTrigger: {
                id: "useStagesAnimation1",
                trigger: refTarget.current,
                scroller: ".scroll-container",
              //  scrub: 1,
                pin: false,
                start: "top-=60% top",
                end: "200% bottom",
                anticipatePin: 1,
                onUpdate: (self: any) => {
                    const item1 = document.querySelector('.stages-section__item:nth-child(1)') as HTMLDivElement;
                    const item2 = document.querySelector('.stages-section__item:nth-child(2)') as HTMLDivElement;
                    const item3 = document.querySelector('.stages-section__item:nth-child(3)') as HTMLDivElement;
                    if (self.progress >= 0.33 && self.progress < 0.66 ) {
                        item1.style.background = 'transparent';
                        item2.style.background = '#FB6020FF';
                        item3.style.background = 'transparent';
                        //   gsap.to(item2, { background: '#FB6020FF' });

                    }
                    if (self.progress >= 0.66) {
                        item1.style.background = 'transparent';
                        item3.style.background = '#FB6020FF';
                        item2.style.background = 'transparent';

                    }
                    if (self.progress <= 0.33) {
                        item2.style.background = 'transparent';
                        item1.style.background = '#FB6020FF';
                        item3.style.background = 'transparent';

                    }
                },
            },
        })
        gsap.timeline({
            scrollTrigger:{
                id: "useStagesAnimation2",
                trigger: refTarget.current,
                scroller: ".scroll-container",
            }
        }).fromTo('.stages-section__item',{
                    opacity:0,
                    x: 0,
                    y: '10vw',
                    duration: 1,
                    delay: 0.1,
                    stagger: 0.5, // Задержка между появлением каждой буквы
                },
                {
                    opacity:1,
                    x: 0,
                    y: '0vw',
                    duration: 1,
                    delay: 0.1,
                    stagger: 0.5, // Задержка между появлением каждой буквы
                }
            );

    };

    const animationMob = (timeline:any) => {
        timeline.to(refTarget.current, {
            scrollTrigger: {
                id: "useStagesAnimation1",
                trigger: refTarget.current,
                scroller: ".scroll-container",
                //  scrub: 1,
                pin: false,
                start: "top-=60% top",
                end: "200% bottom",
                anticipatePin: 1,
                onUpdate: (self: any) => {
                    const item1 = document.querySelector('.stages-section__item:nth-child(1)') as HTMLDivElement;
                    const item2 = document.querySelector('.stages-section__item:nth-child(2)') as HTMLDivElement;
                    const item3 = document.querySelector('.stages-section__item:nth-child(3)') as HTMLDivElement;
                    //   gsap.set('.stages-section__item', { background: 'transparent' });
                    // const wrapper = document.querySelector('.stages-section__parallax')as HTMLDivElement;
                    //      wrapper.style.transform = `translateY(-${(self.progress*100)/5}%)`;
                    if (self.progress >= 0.33 && self.progress < 0.66 ) {
                        item1.style.background = 'transparent';
                        item2.style.background = '#FB6020FF';
                        item3.style.background = 'transparent';
                        //   gsap.to(item2, { background: '#FB6020FF' });

                    }
                    if (self.progress >= 0.66) {
                        item1.style.background = 'transparent';
                        item3.style.background = '#FB6020FF';
                        item2.style.background = 'transparent';

                    }
                    if (self.progress <= 0.33) {
                        item2.style.background = 'transparent';
                        item1.style.background = '#FB6020FF';
                        item3.style.background = 'transparent';

                    }
                },
            },
        })
        gsap.timeline({
            scrollTrigger:{
                id: "useStagesAnimation2",
                trigger: refTarget.current,
                scroller: ".scroll-container",
            }
        }).fromTo('.stages-section__item',{
                    opacity:0,
                    x: 0,
                    y: '10vw',
                    duration: 1,
                    delay: 0.1,
                    stagger: 0.5, // Задержка между появлением каждой буквы
                },
                {
                    opacity:1,
                    x: 0,
                    y: '0vw',
                    duration: 1,
                    delay: 0.1,
                    stagger: 0.5, // Задержка между появлением каждой буквы
                }
            );
    };

    const {preloader,preloaderStatus} = useSelectorEx(state => state.app)

    useLayoutEffect(() => {
        if(!preloader && preloaderStatus >= 100) {
            const timeline = gsap.timeline({
                scrollTrigger:{
                    id: "useStagesAnimation2",
                    trigger: refTarget.current,
                    scroller: ".scroll-container",
                }
            });


            const animate = () => {
                let screenWidth:any = window.innerWidth;
                if (screenWidth >= 768) {
                    animationLarge(timeline);
                } else {
                    animationMob(timeline);
                }
            };

            animate();

            const handleResize = () => {
                if(ScrollTrigger.getAll().length >= 1) {
                    ScrollTrigger.getById("useStagesAnimation1")?.kill(true);
                    ScrollTrigger.getById("useStagesAnimation2")?.kill(true);
                }
                timeline.kill();
                timeline.clear();
                animate();
            };

            window.addEventListener('resize', handleResize);

            return () => {
                timeline.kill();
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [preloader,preloaderStatus]);

    return {
        refTarget,
        refTarget1
    };
};

export default useStagesAnimation;


