import React, {useCallback, useEffect, useRef} from 'react';
import Header from '../components/sections/organisms/sections/Header/Header';
import MainSection from "../components/sections/organisms/sections/MainSection/MainSection";
import LuddySection from "../components/sections/organisms/sections/Luddy/LuddySection";
import MarqueeSection from "../components/sections/organisms/sections/Marquee/MarqueeSection";
import ExperienceSection from "../components/sections/organisms/sections/Experience/ExperienceSection";
import TargetSection from "../components/sections/organisms/sections/Target/TargetSection";
import CasesSection from "../components/sections/organisms/sections/CasesSection/CasesSection";
import StagesSection from "../components/sections/organisms/sections/Stages/StagesSection";
import ContactSection from "../components/sections/organisms/sections/ContactSection/ContactSection";
import AchievementsSection from "../components/sections/organisms/sections/AchievementsSection/AchievementsSection";
import PeopleSection from "../components/sections/organisms/sections/People/PeopleSection";
import FormSection from "../components/sections/organisms/sections/Form/FormSection";
import Footer from "../components/sections/organisms/sections/Footer/Footer";
import {LocomotiveScrollProvider} from 'react-locomotive-scroll'
import ScrollTriggerProxy from '../components/ScrollTriggerProxy';
import Preloader from "../components/Preloader";
import usePreloader from "../hooks/usePreloader";

import people from '../assets/images/people.png';
import duck from '../assets/images/duck.png';
import {useDispatchEx, useSelectorEx} from "../hooks/redux";
import MenuModal from "../components/other/MenuModal";
import AnimatedCursor from "react-animated-cursor";
const Home = () => {
    const containerRef = useRef<any>(null)
    const {preloader} = useSelectorEx(state => state.app)
    const images = [
        people,
        duck
    ];
    const dispatch = useDispatchEx();
    const isLoading = usePreloader(images);
    useEffect(() => {
        if(!isLoading){
            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }
        }
    }, [isLoading]);


    return (
        <>
            <AnimatedCursor
                innerSize={8}
                outerSize={40}
                color='251, 96, 32'
                outerAlpha={0.2}
                innerScale={0.7}
                outerScale={3}

                clickables={[
                    'a',
                    'input[type="text"]',
                    'input[type="email"]',
                    'input[type="number"]',
                    'input[type="submit"]',
                    'input[type="image"]',
                    'label[for]',
                    'select',
                    'textarea',
                    'button'
                ]}
            />

            <MenuModal/>
        <LocomotiveScrollProvider
            options={
                {
                    smooth: true,
                    lerp: 0.1,
                    getSpeed: true,
                    getDirection: true,
                    multiplier: 1,
                    firefoxMultiplier: 50,
                    reloadOnContextChange:true
                }
            }
            watch={
                    [
                            //..all the dependencies you want to watch to update the scroll.
                            //  Basicaly, you would want to watch page/location changes
                            //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
                    ]
            }
            containerRef={containerRef}
        >
        <main className={'scroll-container'} data-scroll-container ref={containerRef}>
            <ScrollTriggerProxy />
            <Header/>

            <MainSection/>
            <LuddySection/>
            <MarqueeSection/>
            <ExperienceSection/>
            <TargetSection/>
            <CasesSection/>
            <StagesSection/>
            <ContactSection/>
            <AchievementsSection/>
            <PeopleSection/>
            <FormSection/>
            <Footer/>
        </main>
        {preloader && <Preloader/>}

       </LocomotiveScrollProvider>
        </>
    );
};

export default Home;