import React, {useEffect, useLayoutEffect, useRef} from 'react';

import img1 from '../../../../../assets/images/brands/1.svg';
import img2 from '../../../../../assets/images/brands/2.svg';
import img3 from '../../../../../assets/images/brands/3.svg';
import img4 from '../../../../../assets/images/brands/4.svg';
import img5 from '../../../../../assets/images/brands/5.svg';
import img6 from '../../../../../assets/images/brands/6.svg';
import img7 from '../../../../../assets/images/brands/7.svg';
import img8 from '../../../../../assets/images/brands/8.svg';
import img9 from '../../../../../assets/images/brands/9.svg';
import img10 from '../../../../../assets/images/brands/10.svg';
import img11 from '../../../../../assets/images/brands/11.svg';
import img12 from '../../../../../assets/images/brands/12.svg';
import img13 from '../../../../../assets/images/brands/13.svg';
import img14 from '../../../../../assets/images/brands/14.svg';
import useFormattedStripEffect from "../../../../../hooks/useFormattedStrip";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import usePeopleAnimation from "./usePeopleAnimation";
gsap.registerPlugin(ScrollTrigger);

const PeopleSection = () => {
    const stripRef1 = useFormattedStripEffect(0.02);

    const {refTarget,refTarget1,refTarget2} = usePeopleAnimation();

    return (
        <section ref={refTarget} className={'people-section'}  id="people">
            <div className="container">
                <h2 ref={stripRef1} className="h2 people-section__h2 strip">
                    <span>People cooperate</span>
                    <span className="people-section__header">with market leaders</span>
                </h2>
            </div>
            <div className="people-section__wrapper">
                <div className="people-section__white-line" >{/*data-scroll={true}  data-scroll-speed={-10} data-scroll-direction={"horizontal"} data-scroll-delay={0.05}*/}
                    <ul ref={refTarget1} >
                        <li>OUR CLients</li>
                        <li>OUR CLients</li>
                        <li>OUR CLients</li>
                        <li>OUR CLients</li>
                        <li>OUR CLients</li>
                        <li>OUR CLients</li>
                        <li>OUR CLients</li>
                        <li>OUR CLients</li>
                        <li>OUR CLients</li>
                        <li>OUR CLients</li>
                    </ul>
                </div>
                <div className="people-section__orange-line">{/*data-scroll={true}  data-scroll-speed={10} data-scroll-direction={"horizontal"} data-scroll-delay={0.05}*/}
                    <ul ref={refTarget2} >
                        <li><img src={img1} alt=""/></li>
                        <li><img src={img2} alt=""/></li>
                        <li><img src={img3} alt=""/></li>
                        <li><img src={img4} alt=""/></li>
                        <li><img src={img5} alt=""/></li>
                        <li><img src={img6} alt=""/></li>
                        <li><img src={img7} alt=""/></li>
                        <li><img src={img8} alt=""/></li>
                        <li><img src={img9} alt=""/></li>
                        <li><img src={img10} alt=""/></li>
                        <li><img src={img11} alt=""/></li>
                        <li><img src={img12} alt=""/></li>
                        <li><img src={img13} alt=""/></li>
                        <li><img src={img14} alt=""/></li>
                    </ul>

                </div>
            </div>
        </section>
    );
};

export default PeopleSection;