import React, {useLayoutEffect, useRef} from 'react';
import CardBlock from "../../../../other/CardBlock";
import TargetIcon from "../../../../icons/targetsIcon/TargetIcon";
import ContextIcon from "../../../../icons/targetsIcon/ContextIcon";
import LaptopIcon from "../../../../icons/targetsIcon/LaptopIcon";
import useFormattedStripEffect from "../../../../../hooks/useFormattedStrip";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import useTargetAnimation from "./useTargetAnimation";
gsap.registerPlugin(ScrollTrigger);
const TargetSection = () => {

    const {refTarget} = useTargetAnimation();
    const stripRef1 = useFormattedStripEffect(0.02);

    return (
        <section ref={refTarget} className={'target-section'}   id="target">
            <div className="container">
                <div className="target-section__wrapper">
                    <div className="target-section__header">
                        <h2 ref={stripRef1}  className="h2 target-section__h2 strip">
                            <span>Targeted advertising</span><br/>
                            <span className={'test'}>the main sales channel for</span><br/>
                            most small businesses in 2023
                        </h2>
                    </div>
                    <div className="target-section__items">
                        <div className="target-section__item">
                            <CardBlock className={'card-block-black card-block-target'} image={<TargetIcon/>} title={'Targeted advertisement'} description={<>- is fast sales. What you need in times of financial crisis</>} button={'Negotiated project'} onClick={()=>{}}/>
                        </div>
                        <div className="target-section__item">
                            <CardBlock className={'card-block-orange card-block-context'} image={<ContextIcon/>} title={'Contextual advertisement'} description={<>- a Google search. The warmest <br/>customers who know what they want</>}  button={'Negotiated project'} onClick={()=>{}}/>
                        </div>
                        <div className="target-section__item">
                            <CardBlock className={'card-block-black card-block-laptop'} image={<LaptopIcon/>} title={<>Media <br/>advertising</>} description={<>- increases brand awareness. Suitable <br/>if you are playing the long game</>} button={'Negotiated project'} onClick={()=>{}}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TargetSection;