import React from 'react';

const CartIcon = () => {
    return (
        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M58.4199 23.9375H27.4043L25.4199 18.25C24.8879 16.7176 23.8919 15.3887 22.5704 14.4479C21.2488 13.5072 19.6671 13.0011 18.0449 13H13.1074C12.693 13 12.2956 13.1646 12.0026 13.4576C11.7095 13.7507 11.5449 14.1481 11.5449 14.5625C11.5449 14.9769 11.7095 15.3743 12.0026 15.6674C12.2956 15.9604 12.693 16.125 13.1074 16.125H18.0449C19.0174 16.1213 19.9669 16.4201 20.762 16.98C21.557 17.5399 22.1583 18.3332 22.4824 19.25L24.0449 23.9375L27.8418 34.7031L32.0449 46.8125C32.5779 48.3475 33.5762 49.6781 34.9009 50.6192C36.2255 51.5601 37.8107 52.0646 39.4355 52.0625H47.4824C47.8968 52.0625 48.2942 51.8979 48.5873 51.6049C48.8803 51.3119 49.0449 50.9145 49.0449 50.5C49.0449 50.0856 48.8803 49.6882 48.5873 49.3952C48.2942 49.1022 47.8968 48.9375 47.4824 48.9375H39.4355C38.4605 48.9446 37.5074 48.6473 36.7093 48.0872C35.9111 47.527 35.3075 46.7319 34.9824 45.8125H51.8105C53.9696 45.8187 56.066 45.0882 57.7536 43.7415C59.4411 42.3949 60.6188 40.5128 61.0918 38.4062L62.9981 29.6094C63.1448 28.9262 63.137 28.2189 62.9751 27.5393C62.8133 26.8595 62.5015 26.2246 62.0626 25.6809C61.6238 25.1372 61.0689 24.6984 60.4386 24.3968C59.8084 24.0952 59.1186 23.9382 58.4199 23.9375ZM59.9824 28.9375L58.0762 37.7344C57.7529 39.1476 56.9575 40.4085 55.8212 41.3089C54.6851 42.2094 53.2759 42.6956 51.8261 42.6875H33.9668L30.7637 33.625L28.4824 27.0625H58.4199C58.6664 27.0558 58.911 27.1075 59.1337 27.2134C59.3564 27.3193 59.5508 27.4764 59.7011 27.6719C59.8359 27.8504 59.9307 28.0558 59.9792 28.2742C60.0278 28.4925 60.0289 28.7187 59.9824 28.9375Z" fill="white"/>
            <path d="M31.8579 52.062C30.9308 52.062 30.0245 52.337 29.2537 52.852C28.4828 53.3671 27.882 54.0992 27.5272 54.9557C27.1724 55.8122 27.0796 56.7547 27.2605 57.664C27.4413 58.5733 27.8878 59.4085 28.5434 60.0641C29.1989 60.7196 30.0341 61.1661 30.9434 61.3469C31.8527 61.5278 32.7952 61.435 33.6518 61.0802C34.5083 60.7254 35.2404 60.1246 35.7555 59.3537C36.2705 58.5829 36.5454 57.6766 36.5454 56.7495C36.5454 55.5063 36.0515 54.314 35.1725 53.435C34.2934 52.5559 33.1011 52.062 31.8579 52.062ZM31.8579 58.312C31.5489 58.312 31.2468 58.2203 30.9899 58.0487C30.7329 57.877 30.5326 57.6329 30.4143 57.3475C30.2961 57.062 30.2651 56.7478 30.3254 56.4447C30.3857 56.1416 30.5345 55.8632 30.7531 55.6447C30.9716 55.4261 31.25 55.2773 31.5531 55.217C31.8562 55.1567 32.1704 55.1877 32.4559 55.306C32.7413 55.4242 32.9854 55.6245 33.1571 55.8815C33.3287 56.1384 33.4204 56.4405 33.4204 56.7495C33.4204 57.1639 33.2558 57.5613 32.9627 57.8543C32.6697 58.1474 32.2723 58.312 31.8579 58.312Z" fill="white"/>
            <path d="M53.7329 52.062C52.8058 52.062 51.8995 52.337 51.1287 52.852C50.3578 53.3671 49.757 54.0992 49.4023 54.9557C49.0474 55.8122 48.9546 56.7547 49.1355 57.664C49.3164 58.5733 49.7628 59.4085 50.4184 60.0641C51.0739 60.7196 51.9091 61.1661 52.8184 61.3469C53.7277 61.5278 54.6702 61.435 55.5268 61.0802C56.3833 60.7254 57.1153 60.1246 57.6304 59.3537C58.1455 58.5829 58.4204 57.6766 58.4204 56.7495C58.4204 55.5063 57.9265 54.314 57.0475 53.435C56.1684 52.5559 54.9761 52.062 53.7329 52.062ZM53.7329 58.312C53.4239 58.312 53.1218 58.2203 52.8649 58.0487C52.6079 57.877 52.4076 57.6329 52.2894 57.3475C52.1711 57.062 52.1401 56.7478 52.2004 56.4447C52.2607 56.1416 52.4095 55.8632 52.6281 55.6447C52.8466 55.4261 53.125 55.2773 53.4281 55.217C53.7312 55.1567 54.0454 55.1877 54.3309 55.306C54.6163 55.4242 54.8604 55.6245 55.0321 55.8815C55.2038 56.1384 55.2954 56.4405 55.2954 56.7495C55.2954 57.1639 55.1308 57.5613 54.8378 57.8543C54.5448 58.1474 54.1473 58.312 53.7329 58.312Z" fill="white"/>
        </svg>
    );
};

export default CartIcon;